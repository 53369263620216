import React, { useState, useEffect, } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import * as helpAction from "../../store/actions/help"
import SearchIcon from '@mui/icons-material/Search';
import SaveModal from "../../reUsable/SaveModal";

import { toast } from 'react-toastify';
import { ContentState, convertToRaw, EditorState, convertFromHTML, } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
function Terms({ help, update, getHelp, }) {
    const [modal, setModal] = useState(false)
    const [type, setType] = useState('terms')
    const [flag, setFlag] = useState('Terms of Service')
    const [editorState, setEditorState] = useState(
        EditorState.createEmpty()
    );
    useEffect(() => {
        search()
    }, [])

    const handleSave = () => {
        const data = {
            key: type,
            desc: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
        update(data).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
            search()
            setModal(false)
        })

    }


    function search() {
        setEditorState(EditorState.createEmpty())

        getHelp({ key: type }).then(res => {
            if (res.success) {
                if (res.data) {
                    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res?.data?.desc))))
                }
            }
        })

    }


    return (
        <div className="container-fluid mb-3">
            <SaveModal
                visible={modal}
                title={`Are you sure you want to save these changes?`}
                close={() => setModal(false)}
                type="status"
                call={() => handleSave()}
            />
            {/* <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Help
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" /> */}
            <Row className="d-flex justify-content-between mt-2 ">
                <Col xl={12} lg={12} md={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                {flag}
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <Row className='mb-3'>
                            <Col xs={12} md={3} lg={3}>
                                <FormControl className='field-style' size="small">
                                    <InputLabel
                                        id="demo-simple-select-label1"
                                    >Type</InputLabel>
                                    <Select
                                        sx={{ color: "black" }}
                                        labelId="Type"
                                        id="Type"
                                        value={type}
                                        label="Type"
                                        onChange={(e) => {
                                            let val = e.target.value
                                            setType(e.target.value)
                                            setEditorState(EditorState.createEmpty())
                                            if (val === "terms") {
                                                setFlag('Terms of Service')
                                            } else if (val === "privacy") {
                                                setFlag('Privacy Policy')
                                            } else if (val === "guidelines") {
                                                setFlag('Community Guidelines')
                                            } else if (val === "faq") {
                                                setFlag("FAQ's")
                                            } else if (val === "shareandearn") {
                                                setFlag("Share & Earn")
                                            }
                                            getHelp({ key: val }).then(res => {
                                                if (res.success) {
                                                    if (res.data) {
                                                        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res?.data?.desc))))
                                                    }
                                                }
                                            })
                                        }}
                                    >
                                        <MenuItem key={"termsOfService"} value={"terms"} >Terms of Service</MenuItem>
                                        <MenuItem key={"privacyPolicy"} value={"privacy"} >Privacy Policy</MenuItem>
                                        <MenuItem key={"communityGuidelines"} value={"guidelines"} >Community Guidelines</MenuItem>
                                        <MenuItem key={"faqs"} value={"faq"} >FAQ's</MenuItem>
                                        <MenuItem key={"shareandearn"} value={"shareandearn"} >Share & Earn</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            {/* <Col xs={12} md={3} lg={3} >
                                <Button
                                    className="btn-width btn-gen-margin ml-0 h-100 "
                                    disableElevation
                                    size="medium"
                                    variant="contained"
                                    startIcon={<SearchIcon />}
                                    onClick={search}
                                >
                                    Search
                                </Button>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col xs={12} >
                                <Editor
                                    editorStyle={{
                                        padding: '0px 15px',
                                        width: '100%',
                                        maxWidth: '100%',
                                        minHeight: "60vh",
                                        maxHeight: "60vh",
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'lightgray'
                                    }}
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    onEditorStateChange={setEditorState}
                                />
                                <Button
                                    className="btn-width btn-gen-margin ml-0 h-100 mt-2"
                                    disableElevation
                                    size="medium"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    onClick={() => setModal(true)}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

        </div>
    )
}

const mapStateToProps = ({ help }) => {
    return { help };
};

export default connect(mapStateToProps, helpAction)(Terms);