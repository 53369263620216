import React, { Component, useEffect, useState, lazy, Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./auth/LoginComponent";
import SkeletonLoader from "../reUsable/skeleton"
import DrawerHeader from "./drawer"
import * as authActions from "../store/actions/auth"
import { toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

////////////
// const DashboardComponent = React.lazy(() => lazyRetry(() => import("./dashboard/DashboardComponent")))
// const AccountsInfluencer = React.lazy(() => lazyRetry(() => import("./accountMain/AccountInfluencer")))
// const Country = React.lazy(() => lazyRetry(() => import("./country/Country")))
// const Notification = React.lazy(() => lazyRetry(() => import("./notification/Notification")))
// const AccountsBrand = React.lazy(() => lazyRetry(() => import("./accountMain/AccountBrand")))
// const AccountsCustomer = React.lazy(() => lazyRetry(() => import("./accountMain/AccountCustomer")))
// const Banners = React.lazy(() => lazyRetry(() => import("./Banners/AdManage")))
// // const AdManage = React.lazy(() => lazyRetry(() => import("./AdManager/AdManager")))
// const NotMatch = React.lazy(() => lazyRetry(() => import("./NotMatch")))
// const AppSetting = React.lazy(() => lazyRetry(() => import("./Settings/appSetting/AppSetting")))
// const GeneralCategory = React.lazy(() => lazyRetry(() => import("./MainCategories/GeneralCategory/GeneralCateogry")))
// const Category = React.lazy(() => lazyRetry(() => import("./MainCategories/Category/Category")))
// const SubCategory = React.lazy(() => lazyRetry(() => import("./MainCategories/SubCategory/SubCategory")))
// const BrandAdd = React.lazy(() => lazyRetry(() => import("./accountMain/components/AddBrand")))
// const BrandEdit = React.lazy(() => lazyRetry(() => import("./accountMain/components/UpdateBrand")))
// const UserEdit = React.lazy(() => lazyRetry(() => import("./accountMain/components/EditUser")))
// const Payout = React.lazy(() => lazyRetry(() => import("./payout/Payout")))
// const Release = React.lazy(() => lazyRetry(() => import("./payout/Release")))
// const BoostSettings = React.lazy(() => lazyRetry(() => import("./Settings/boostSettings/BoostSettings")))
// const VideoBoost = React.lazy(() => lazyRetry(() => import("./videoBoost/VideoBoost")))
// const FeedStats = React.lazy(() => lazyRetry(() => import("./feedStats/FeedStats")))
// const VideoStats = React.lazy(() => lazyRetry(() => import("./feedStats/VideoStats")))
// const FeedSetting = React.lazy(() => lazyRetry(() => import("./feed/FeedSetting")))
// const ManageReviews = React.lazy(() => lazyRetry(() => import("./ContentManage/ManageReviews")))
// const FeesSettings = React.lazy(() => lazyRetry(() => import("./Settings/FeesSetting/FeesSettings")))
// const TotalSales = React.lazy(() => lazyRetry(() => import("./salesReports/TotalSales")))
// const Moderation = React.lazy(() => lazyRetry(() => import("./ContentManage/Moderation")))
// const Dashboard = React.lazy(() => lazyRetry(() => import("./dashboard/Dashboard")))
// const VideoSettings = React.lazy(() => lazyRetry(() => import("./Settings/videoSettings/VideoSettings")))
// const PayoutSetting = React.lazy(() => lazyRetry(() => import("./Settings/payoutSetting/PayoutSetting")))
// const HouseAdd = React.lazy(() => lazyRetry(() => import("./accountMain/components/AddHouse")))
// const Shipping = React.lazy(() => lazyRetry(() => import("./Shipping/Shipping")))
// const Terms = React.lazy(() => lazyRetry(() => import("./Terms/Terms")))
// const Logs = lazy(() => import("./logs/logs"))
// // const PriceTags = React.lazy(() => lazyRetry(() => import("./PriceTags/PriceTags")))
// const PricePoint = React.lazy(() => lazyRetry(() => import("./PriceTags/PricePoint")))
// const Code = React.lazy(() => lazyRetry(() => import("./Settings/Code/Code")))

//without Lazy
import DashboardComponent from "./dashboard/DashboardComponent"
import AccountsInfluencer from "./accountMain/AccountInfluencer"
import Country from "./country/Country"
import Notification from "./notification/Notification"
import AccountsBrand from "./accountMain/AccountBrand"
import Agency from "./accountMain/Agency"
import AccountsCustomer from "./accountMain/AccountCustomer"
import Banners from "./Banners/AdManage"
// import AdManage from "./AdManager/AdManager"
import NotMatch from "./NotMatch"
import AppSetting from "./Settings/appSetting/AppSetting"
import GeneralCategory from "./MainCategories/GeneralCategory/GeneralCateogry"
import Category from "./MainCategories/Category/Category"
import SubCategory from "./MainCategories/SubCategory/SubCategory"
import BrandAdd from "./accountMain/components/AddBrand"
import BrandEdit from "./accountMain/components/UpdateBrand"
import UserEdit from "./accountMain/components/EditUser"

import BoostSettings from "./Settings/boostSettings/BoostSettings"
import VideoBoost from "./videoBoost/VideoBoost"
import FeedStats from "./feedStats/FeedStats"
import VideoStats from "./feedStats/VideoStats"
import FeedSetting from "./feed/FeedSetting"
import ManageReviews from "./ContentManage/ManageReviews"
import FeesSettings from "./Settings/FeesSetting/FeesSettings"
import TotalSales from "./salesReports/TotalSales"
import Moderation from "./ContentManage/Moderation"
import Dashboard from "./dashboard/Dashboard"
import VideoSettings from "./Settings/videoSettings/VideoSettings"
import HouseAdd from "./accountMain/components/AddHouse"
import Shipping from "./Shipping/Shipping"
import Terms from "./Terms/Terms"
import Logs from "./logs/logs.js"
// import PriceTags from "./PriceTags/PriceTags"
import PricePoint from "./PriceTags/PricePoint"
import Code from "./Settings/Code/Code"
import Returns from "./returns/Returns"
import TEST from "./TEST"
import AdminControl from "./AdminControl/AdminControl";
import Rank from "./Rank/Rank";
import ProductSearch from "./productsSearch/ProductSearch";

import Payout from "./payout/Payout"
import Release from "./payout/Release"
import PayoutSetting from "./Settings/payoutSetting/PayoutSetting"

import AgencyPayout from "./payout/AgencyPayout"
import AgencyRelease from "./payout/AgencyRelease"
import AgencyPayoutSetting from "./Settings/payoutSetting/AgencyPayoutSetting"
import UserShop from "./usershop/UserShop"
import IntroVideo from "./introVideo/IntroVideo"
import ForyouFeed from "./feed/ForyouFeed";
import Promotion from "./promotion/Promotion";
import BrandStats from "./feedStats/BrandStats";
import PromotionReport from "./promotion/PromotionReport";
import EmailBlastSetting from "./Settings/emailBlast/EmailBlastSetting";
import Messages from "./messages/Messages";
import NotificationMSG from "./notificationmsg/NotificationMSG";
import UserDetail from "./accountMain/UserDetail";
import Tags from "./Tags/Tags";
import Discover from "./discover/Discover";
import Comments from "./ContentManage/Comments";
import BanUsers from "./ContentManage/BanUsers";
import Discount from "./discount/Discount";
import PaymentReport from "./promotion/PaymentReport";
import UserRegisterStats from "./feedStats/UserRegisterStats";


// const lazyRetry = function (componentImport) {
//     return new Promise((resolve, reject) => {
//         // check if the window has already been refreshed
//         const hasRefreshed = JSON.parse(
//             window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
//         );
//         // try to import the component
//         componentImport().then((component) => {
//             window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
//             resolve(component);
//         }).catch((error) => {
//             if (!hasRefreshed) { // not been refreshed yet
//                 window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
//                 return window.location.reload(); // refresh the page
//             }
//             reject(error); // Default error behaviour as already tried refresh
//         });
//     });
// };


function Main({ setUser, logOut, user }) {
    const [loading, setLoading] = useState(true)
    var func
    function showMessage() {
        toast.info("Session Expire")
    }
    useEffect(() => {
        // console.log("1")
        // getPublicIP()
        //     .then(publicIP => {
        //         console.log('Your public IP address is:', publicIP);
        //     })
        //     .catch(error => {
        //         console.error('Error getting public IP:', error);
        //     });
        setUser(logOut, () => setLoading(false), showMessage)
    }, [])

    function refreshMain(cb) {
        func = cb
    }

    if (!loading) {
        if (user?.success) {
            if (user.message.email === "finance@ormelive.com") {
                return (
                    <>
                        <DrawerHeader refresh={() => func()}>
                            <Switch>
                                <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
                                <Route exact path="/login" component={() => <Redirect to="/dashboard" />} />
                                <Route exact path="/dashboard" component={() => <Dashboard refresh={refreshMain} />} />
                                <Route exact path="/totalSales" component={TotalSales} />
                                {/* <Route exact path="/requests" component={Payout} />
                                <Route exact path="/department" component={GeneralCategory} />
                                <Route exact path="/micro" component={TEST} />
                                <Route exact path="/category" component={Category} />
                                <Route exact path="/subCategory" component={SubCategory} />
                                <Route exact path="/brand" component={AccountsBrand} />
                                <Route exact path="/agency" component={Agency} />
                                <Route exact path="/brand/add" component={BrandAdd} />
                                <Route exact path="/house/add" component={HouseAdd} />
                                <Route exact path="/customer" component={AccountsCustomer} />
                                <Route exact path="/banners" component={Banners} />
                                <Route exact path="/notification" component={Notification} />
                                <Route exact path="/boostedVideos" component={VideoBoost} />
                                <Route exact path='/country' component={Country} />
                                <Route exact path="/influencer" component={AccountsInfluencer} />
                                <Route exact path="/house" component={() => <AccountsInfluencer type={'house'} />} />

                                <Route exact path="/userStats" component={FeedStats} />
                                <Route exact path="/videoStats" component={VideoStats} />
                                <Route exact path="/manageReview" component={ManageReviews} />
                                <Route exact path="/moderation" component={Moderation} />
                                <Route exact path="/pricePoints" component={PricePoint} />
                                <Route exact path="/returns" component={Returns} />
                                <Route exact path="/rank" component={Rank} /> */}
                                <Route component={NotMatch} />
                            </Switch>
                        </DrawerHeader>
                    </>
                )
            } else {
                return (
                    <>
                        <DrawerHeader refresh={() => func()}>
                            <Switch>
                                <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
                                <Route exact path="/login" component={() => <Redirect to="/dashboard" />} />
                                {/* <Route exact path="/dashboard" component={() => <DashboardComponent refresh={refreshMain} />} /> */}
                                <Route exact path="/dashboard" component={() => <Dashboard refresh={refreshMain} />} />

                                {/* <Route exact path="/payout" component={Payout} /> */}
                                <Route exact path="/department" component={GeneralCategory} />
                                <Route exact path="/micro" component={TEST} />
                                <Route exact path="/category" component={Category} />
                                <Route exact path="/subCategory" component={SubCategory} />

                                <Route exact path="/brand" component={AccountsBrand} />
                                <Route exact path="/agency" component={Agency} />
                                <Route exact path="/brand/add" component={BrandAdd} />
                                {/* <Route exact path="/brand/edit/:id" component={BrandEdit} /> */}
                                <Route exact path="/house/add" component={HouseAdd} />
                                {/* <Route exact path="/:type/edit/:id" component={UserEdit} /> */}
                                <Route exact path="/customer" component={AccountsCustomer} />
                                {/* <Route exact path="/adManager" component={AdManage} /> */}
                                <Route exact path="/banners" component={Banners} />
                                {/* <Route exact path="/notification" component={Notification} /> */}
                                <Route exact path="/boostedVideos" component={VideoBoost} />
                                <Route exact path='/country' component={Country} />
                                <Route exact path="/influencer" component={AccountsInfluencer} />
                                {/* <Route exact path="/userDetails" component={UserDetail} /> */}
                                <Route exact path="/house" component={() => <AccountsInfluencer type={'house'} />} />

                                <Route exact path="/userStats" component={FeedStats} />
                                <Route exact path="/userRegisterStats" component={UserRegisterStats} />
                                <Route exact path="/brandStats" component={BrandStats} />
                                <Route exact path="/videoStats" component={VideoStats} />
                                <Route exact path="/manageReview" component={ManageReviews} />
                                <Route exact path="/totalSales" component={TotalSales} />
                                <Route exact path="/moderation" component={Moderation} />
                                <Route exact path="/pricePoints" component={PricePoint} />
                                <Route exact path="/returns" component={Returns} />
                                <Route exact path="/rank" component={Rank} />


                                <Route exact path="/logs" component={Logs} />
                                <Route exact path="/appSetting" component={AppSetting} />
                                <Route exact path="/code" component={Code} />
                                <Route exact path="/boostSetting" component={BoostSettings} />
                                <Route exact path="/feesSetting" component={FeesSettings} />
                                <Route exact path="/videoSetting" component={VideoSettings} />
                                <Route exact path="/emailSetting" component={EmailBlastSetting} />
                                <Route exact path="/terms" component={Terms} />
                                <Route exact path="/adminControl" component={AdminControl} />
                                <Route exact path="/shipping" component={Shipping} />
                                {/* <Route exact path="/reviewFeed" component={() => <FeedSetting type="review" />} />
                                <Route exact path="/showFeed" component={() => <FeedSetting type="show" />} /> */}
                                <Route exact path="/products" component={ProductSearch} />

                                <Route exact path="/requests" component={Payout} />
                                <Route exact path="/release" component={Release} />
                                <Route exact path="/payoutSetting" component={PayoutSetting} />

                                <Route exact path="/agencyRequests" component={AgencyPayout} />
                                <Route exact path="/agencyRelease" component={AgencyRelease} />
                                <Route exact path="/agencyPayoutSetting" component={AgencyPayoutSetting} />
                                {/* <Route exact path="/userShop" component={UserShop} /> */}
                                <Route exact path="/discover" component={Discover} />
                                <Route exact path="/introVideo" component={IntroVideo} />
                                <Route exact path="/followingFeed" component={() => <FeedSetting type="review" />} />
                                <Route exact path="/forYouFeed" component={() => <ForyouFeed type="review" />} />
                                <Route exact path="/promotionCriteria" component={Promotion} />
                                <Route exact path="/promotion" component={PromotionReport} />
                                <Route exact path="/messages" component={NotificationMSG} />
                                <Route exact path="/comments" component={Comments} />
                                <Route exact path="/banusers" component={BanUsers} />
                                <Route exact path="/tags" component={Tags} />
                                <Route exact path="/discounts" component={Discount} />
                                <Route exact path="/paymentReport" component={PaymentReport} />



                                {/* <Route exact path="/microsevices" component={TEST} /> */}
                                {/* <Route exact path="/TEST" component={TEST} /> */}
                                {/* <Route exact path="/reviewFeed" component={FeedReview} /> */}
                                {/* <Route path="/accountMain/detail/:id" component={AccountDetail} /> */}

                                {/* <Route exact path="/test" component={AdManager} /> */}

                                {/* <Route exact path="/newCategory" component={NewCategories} /> */}
                                {/* <Route exact path="/customer" component={AccountsCustomer} />
                                <Route exact path="/brand" component={AccountsBrand} />
                                <Route exact path="/subcategories" component={SubCategory} />
                                <Route exact path="/adManager" component={AdManage} />
                                <Route exact path="/packages" component={Packages} />
                                <Route exact path="/packages/detail/:id" component={PackageDetail} />
                                <Route exact path="/packages/edit/:id" component={EditPackage} />
                                <Route exact path="/packages/add" component={AddPackage} />
                                <Route exact path="/promocodes" component={Promocodes} />
                                <Route exact path='/promocodes/create' component={CreatePromoCode} />
                                <Route exact path='/promocodes/edit/:id' component={EditPromo} />
                                <Route exact path='/authPer' component={AuthPermission} />
                                <Route exact path="/catPopular" component={CategoryPopular} />
                                <Route exact path="/orders" component={OrderReport} />
                                <Route exact path="/referral" component={ReferralStatsReport} />
                                <Route exact path="/marketfee" component={MarketFeeReport} />
                                <Route exact path="/payout" component={PayoutReport} />

                                <Route exact path="/events" component={Events} /> */}

                                {/* <Route exact path="/accountMain" component={AccountsInfluencer} /> */}
                                {/* <Route path="/accountMain/detail/:id" component={AccountDetail} /> */}
                                {/* <Route exact path="/manageAccess" component={Access} /> */}
                                {/* <Route exact path='/subscribtion' component={Subscribtion} /> */}
                                {/* <Route exact path='/userCategories' component={UserCategories} /> */}
                                {/* <Route exact path='/campaign' component={BrandCampaign} />
                                <Route exact path='/campaign/:id' component={Campaign} />
                                <Route exact path='/influencer' component={Influencer} />
                                <Route exact path='/influencerCampaign/:id' component={InfluencerCampaign} /> */}
                                {/* <Route exact path='/updateCampaign/:id' component={EditCampaign} /> */}
                                {/* <Route exact path="/followersRequests" component={FollowersRequests} /> */}
                                {/* <Route exact path="/ledger" component={Ledger} /> */}
                                {/* <Route exact path="/kbcommission" component={() => <KBCommission type='Commission' />} /> */}
                                {/* <Route exact path="/kbearning" component={() => <KBCommission type='Earning' />} /> */}
                                {/* <Route exact path="/kbbalance" component={KBBalance} /> */}
                                {/* <Route exact path="/featured" component={BrandFeatured} /> */}
                                {/* <Route exact path="/bSale" component={() => <Sales type="Brand" />} /> */}
                                {/* <Route exact path="/iSale" component={() => <Sales type="Influencer" />} /> */}
                                {/* <Route exact path="/cSale" component={() => <Sales type="Customer" />} /> */}
                                {/* <Route exact path="/catFeatured" component={FeaturedCategory} /> */}
                                {/* <Route exact path="/topBrand" component={() => <Top type="brand" />} />
                                <Route exact path="/topInfluencer" component={() => <Top type="influencer" />} />
                                <Route exact path="/topCustomer" component={() => <Top type="customer" />} /> */}
                                {/* <Route exact path="/newcategories" component={NewCategory} /> */}
                                {/* <Route exact path="/adPrice" component={AdPrice} /> */}
                                {/* <Route exact path="/sales" component={() => <AccountsSales type="Brand" from="accounting" />} />
                                <Route exact path="/purchase" component={() => <Purchase type="Brand" from="accounting" />} />
                                <Route exact path="/commission" component={Commission} />
                                <Route exact path="/cashback" component={Cashback} />
                                <Route exact path="/withdrawal" component={Withdrawal} />
                                <Route exact path="/deposit" component={Deposit} />
                                <Route exact path="/users" component={User} />
                                <Route exact path='/users/create' component={CreateUser} />
                                <Route exact path='/users/edit/:id' component={EditUser} />
                                <Route exact path='/orders' component={Orders} /> */}
                                <Route component={NotMatch} />
                            </Switch>
                        </DrawerHeader>
                    </>
                )
            }

        } else {
            return (
                <>
                    <Route path="/" component={() => <Redirect to="/login" />} />
                    <Route exact path="/login" component={Login} />
                </>
            )
        }
    } else {
        return <SkeletonLoader />
    }

}

const mapStateToProps = ({ user }) => {
    return { user };
};

export default connect(mapStateToProps, authActions)(Main);

const MainLoad = () => {
    return (
        <div className="mainLoad">
            <SkeletonTheme color="#f0f1f2" highlightColor="#f8f9f9">
                <Skeleton height={50} count={16} />
            </SkeletonTheme>
        </div>
    )
}