import { GET_AUTH_LIST } from "../../actions/type";

const initialState=[];

export default function authList(state=initialState,action){
    switch(action.type){
        case GET_AUTH_LIST:
            return action.payload;
        default:
            return state
    }
}