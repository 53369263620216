import {APP_LOGS} from "../../actions/type";


export default function appLogs(state={},action){
    switch(action.type){
        case APP_LOGS:
            return action.payload;
        default:
            return state
    }
}