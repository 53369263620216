import { CLEAR_COM_INFLUENCER, GET_CAMPAIGN_INFLUENCERS } from "../../actions/type";

const initialState=[]

export default function campaignInfluencers(state=initialState,action){
    switch(action.type){
        case GET_CAMPAIGN_INFLUENCERS:
            return action.payload
        case CLEAR_COM_INFLUENCER:
            return action.payload
        default:
            return state
    }
}