import { TOP_CUSTOMER } from "../../actions/type";

export default function topCustomer(state = {}, action) {
    switch (action.type) {
        case TOP_CUSTOMER:
            return action.payload;
        default:
            return state
    }
}
