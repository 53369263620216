import { combineReducers } from "redux";
import dashboard from "./reducers/dashboard/dashboard";
import accounts from "./reducers/account/acounts";
import accountDetail from "./reducers/account/accountDetail";
import user from "./reducers/user/user";
import packages from "./reducers/package/packages";
import access from "./reducers/access/access";
import packageDetail from "./reducers/package/packageDetail";
import logs from "./reducers/logs/logs";
import promocodes from "./reducers/promocode/promocodes";
import promocodeDetails from "./reducers/promocode/promocodeDetails";
import cancelSubs from "./reducers/subscribtion/cancelSubs";
import approveSubs from "./reducers/subscribtion/approveSubs";
import adminLogs from "./reducers/logs/adminLogs";
import userCategories from "./reducers/userCategories/userCategories";
import authList from "./reducers/authPermissions/authList";
import countries from "./reducers/country/countries";
import brandCampaigns from "./reducers/campain/brandCampaigns";
import campaigns from "./reducers/campain/campains";
import onCampaign from "./reducers/campain/oneCampaign";
import transactions from "./reducers/transactions/transactions";
import brandAndInstagram from "./reducers/campain/brandAndInstagram";
import userSubscriptions from "./reducers/subscribtion/userSubscribtions";
import minimize from "./reducers/minimize/minimize";
import campaignMinimize from "./reducers/minimize/campaignMinimize.js";
import campaignInfluencers from "./reducers/campain/influencers";
import campaignTransaction from "./reducers/campain/transaction";
import InfluencerList from "./reducers/influencerCampaign/influencers";
import InfluencerTable from "./reducers/influencerCampaign/influencerTable";
import influencerCampaign from "./reducers/influencerCampaign/influencerCampaign";
import notificationRed from "./reducers/notificationRed/notificationRed";
import notification from "./reducers/notificationRed/notification";
import sales from "./reducers/sales/sales";
import accountList from "./reducers/account/accountList";
import transactionType from "./reducers/report/transactionType"
import ledger from "./reducers/ledger/ledger"
import accountBalance from "./reducers/account/accountBalance"
import kbCommission from "./reducers/kb/kbCommission"
import kbEarning from "./reducers/kb/kbEarning"
import kbBalance from "./reducers/kb/kbBalance"
import brandFeatured from "./reducers/brandFeature/brandFeature"
import allSales from "./reducers/sales/allSales";
import parentCategories from "./reducers/category/parentCategories";
import accountByCategory from "./reducers/account/accountByCategory";
import bookings from "./reducers/booking/booking"
import brandBooking from "./reducers/booking/brandBooking"
import topBrand from "./reducers/marketing/topBrand";
import topInfluencer from "./reducers/marketing/topInfluencer";
import topCustomer from "./reducers/marketing/topCustomer";
import appLogs from "./reducers/logs/appLogs";
import appSetting from "./reducers/appSetting/appSetting"
import dashboardGraph from "./reducers/dashboard/dashboardGraph"
// import subcategories from "./reducers/subCategory/subCategory"
import appUserLogs from "./reducers/logs/appUserLogs"
import banners from "./reducers/adManage/adManage"
import bannerPrice from "./reducers/adManage/adPrice"
import cashback from "./reducers/cashback/cashback"
import withdrawal from "./reducers/withdrawal/withdrawal"
import deposit from "./reducers/deposit/deposit"
import refund from "./reducers/refund/refund"
import users from "./reducers/users/users"
import userDetail from "./reducers/users/userDetail"
import stores from "./reducers/orders/store"
import orders from "./reducers/orders/orders"
import domain from "./reducers/playback/playbackURL";
import events from "./reducers/events/events";

import genderCategories from "./reducers/Categories/genderCategory";
import categories from "./reducers/Categories/category";
import subcategories from "./reducers/Categories/subCategory";
import refsales from "./reducers/sales/referralSales"
import reviewsales from "./reducers/sales/reviewSales"
import ordersales from "./reducers/sales/orderSales"
import marketfee from "./reducers/sales/marketFee"
import purchases from "./reducers/sales/purchases"
import referral from "./reducers/sales/referral"
import earning from "./reducers/sales/earning"
import purchasers from "./reducers/account/purchaser"
import referrers from "./reducers/account/referrer"
import refSalesInf from "./reducers/sales/reviewSalesInf";
import shopifyCategory from "./reducers/shopify/category";
import payout from "./reducers/payout/payout";
import changePayout from "./reducers/payout/payouthandle";
import feed from "./reducers/feed/feed";
import videos from "./reducers/video/video";
import adfeed from "./reducers/feed/adFeed";
import variantFeed from "./reducers/feed/variantFeed";
import feedUsers from "./reducers/feed/feedUsers";
import feedStats from "./reducers/feed/feedStats";
import feedStatsBrands from "./reducers/feed/feedStatsBrands";
import videoStats from "./reducers/feed/videoStats";
import reviews from "./reducers/contentManage/reviews";
import fees from "./reducers/fees/fees";
import totalSales from "./reducers/sales/totalSales";
import shipping from "./reducers/shipping/shipping";
import help from "./reducers/help/help";
import editUser from "./reducers/users/editUser";
import prices from "./reducers/price/price";
import returns from "./reducers/returns/returns";
import statusDetail from "./reducers/status/status"
import agencies from "./reducers/account/agencies"
import agencyDetail from "./reducers/account/AgencyDetail"
import intro from "./reducers/intro/intro.reducers"
import promotionSales from "./reducers/promocode/promotionSales"
import comments from "./reducers/comments/comments"
import discounts from "./reducers/discount/discount"
import banUserList from "./reducers/comments/banusers"
import stripeDetail from "./reducers/promocode/paymentReport"
import registerStats from "./reducers/feed/RegisterStats"


export default combineReducers({
  intro,
  subcategories,
  categories,
  genderCategories,
  dashboard,
  accounts,
  accountDetail,
  user,
  packages,
  access,
  packageDetail,
  logs,
  promocodes,
  promocodeDetails,
  cancelSubs,
  approveSubs,
  adminLogs,
  userCategories,
  authList,
  countries,
  brandCampaigns,
  onCampaign,
  transactions,
  brandAndInstagram,
  userSubscriptions,
  minimize,
  campaignMinimize,
  campaignInfluencers,
  campaigns,
  campaignTransaction,
  InfluencerList,
  InfluencerTable,
  influencerCampaign,
  notificationRed,
  sales,
  accountList,
  transactionType,
  ledger,
  accountBalance,
  kbCommission,
  kbEarning,
  kbBalance,
  brandFeatured,
  allSales,
  parentCategories,
  accountByCategory,
  bookings,
  brandBooking,
  topBrand,
  topInfluencer,
  topCustomer,
  appLogs,
  appSetting,
  dashboardGraph,
  subcategories,
  appUserLogs,
  banners,
  bannerPrice,
  cashback,
  withdrawal,
  deposit,
  refund,
  users,
  userDetail,
  stores,
  orders,
  domain,
  events,
  refsales,
  ordersales,
  marketfee,
  purchases,
  referral,
  earning,
  purchasers,
  referrers,
  reviewsales,
  refSalesInf,
  shopifyCategory,
  payout,
  changePayout,
  feed,
  videos,
  adfeed,
  variantFeed,
  feedUsers,
  feedStats,
  videoStats,
  reviews,
  fees,
  totalSales,
  shipping,
  help,
  editUser,
  prices,
  returns,
  statusDetail,
  agencies,
  agencyDetail,
  feedStatsBrands,
  promotionSales,
  notification,
  comments,
  discounts,
  banUserList,
  stripeDetail,
  registerStats
});
