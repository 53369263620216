import {GET_COUNTRY, SEARCH_COUNTRY} from "../../actions/type";

const initialState=[];

export default function countries(state=initialState,action){
    switch(action.type){
        case GET_COUNTRY:
            return action.payload;
        default:
            return state
    }
}