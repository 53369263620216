import {CLEAR_USER_CAT, GET_USER_CATEGORY, RESET_CATEGORY, RESET_GLOBAL_CATE} from "../../actions/type";

const initialState={
    data:[],
    totalCount:0
};

export default function userCategories(state=initialState,action){
    switch(action.type){
        case GET_USER_CATEGORY:
            return {...action.payload,data:[...state.data,...action.payload.data]};
        case CLEAR_USER_CAT:
            return action.payload;
        case RESET_CATEGORY:
            const data=[...state.data].map((item,i)=>{
                if(item._id==action.payload.userId){
                    return {
                        ...item,
                        categoriesInfo:item.categoriesInfo.map((catItem)=>{
                            if(catItem._id===action.payload._id){
                                return {
                                    ...catItem,
                                    image_url:action.payload.image_url
                                }
                            }
                            return catItem
                        })
                    }
                }
                return item
            })
            return {
                ...state,
                data:data
            }
        case RESET_GLOBAL_CATE:
            const dataG=[...state.data].map((item,i)=>{
                if(item._id==action.payload.userId){
                    const menu=[...item.menu].map((itemM)=>{
                        if(itemM.id===action.payload.id){
                            return {
                                ...itemM,
                                image_url:action.payload.image_url
                            }
                        }
                        return itemM
                    })
                    return {
                        ...item,
                        menu:[...menu],
                    }
                }
                return item
            })

            return {
                ...state,
                data:dataG
            }
        default:
            return state
    }
}