import {GET_ACCOUNT_DETAIL } from "../../actions/type";

const initialState=[];

export default function accountDetail(state=initialState,action){
    switch(action.type){
        case GET_ACCOUNT_DETAIL:
            return action.payload;
        default:
            return state
    }
}