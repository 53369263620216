import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Divider, Box } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import * as accountActions from "../../store/actions/account"
import SkeletonLoader from "reUsable/skeleton";
import * as packActions from "../../store/actions/package"
import * as countryActions from "../../store/actions/country"
import ClearIcon from '@mui/icons-material/Clear';
import DataTable from "./table";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { columns } from "./columns"
import { CSVLink } from 'react-csv';
import CSVButton from "../../reUsable/CSVButton"
import AddIcon from '@mui/icons-material/Add';
import AddBrand from "./components/AddBrand";
import { useHistory } from 'react-router-dom';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import moment from "moment";
import DateBtn from "reUsable/DateBtn";

function Accounts({ getAccount, accounts, getPackages, packages, getCountry, countries, getStates, getCity, clearAccounts, getAccountList, accountList, ...rest }) {
    // console.log("111", Object.values(rest?.location)[1])
    const history = useHistory()
    const fileName = "brandDetail";
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [search, setSearch] = useState(true)
    const [fields, setFields] = useState({
        name: "",
        email: "",
        status: 'active',
        country: "",
        state: "",
        city: "",
        show_delete_user: false,
        account_type: "brand",
        app: true,
        user_id: '',
        orderBy: -1,
        sort: "pixel_id",
        agreement: 'accepted',
        ecom: 'all',
        ecom_status: 'connected',
        stripe_type: 'all',
        stripe_status: 'connected',
        ig_status: 'all',
    })

    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [countryName, setCountryName] = useState("")
    const [stateName, setStateName] = useState("")
    const [submit, setSubmit] = useState(false)
    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(false)
    const [header, setHeader] = useState([])
    const [data, setData] = useState([])
    const [load, setLoad] = useState(false)

    const [email, setEmail] = useState("")
    const [emailLoad, setEmailLoad] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [value, setValue] = useState([
        moment('2023-1-1').format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [date, setDate] = useState(false);
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        getCountry()
        onSearch()
        return () => {
            dispatch({
                type: "clearMinimize"
            })
        }
    }, [])

    useEffect(() => {
        setHeader(columns.map(m => ({ label: m.headerName, key: m.field })))
    }, [])

    useEffect(() => {
        if (date) {
            onSearch()
        }
    }, [date])
    function onSearch() {
        setSubmit(true)
        setLoading(true)
        const upFields = { ...fields }
        if (fields.status == 'deactive') {
            upFields.is_active = false
            // upFields.is_deleted = false
        } else if (fields.status == 'active') {
            upFields.is_active = true
        } else if (fields.status == 'incomplete') {
            upFields.is_active = true
        } else if (fields.status == 'new') {
            upFields.is_active = 'new'
        } else if (fields.status == 'deleted') {
            // upFields.is_active = true
            // upFields.show_delete_user = true
            // upFields.is_deleted = true
        } else {
            upFields.is_active = undefined;
        }
        setTableLoading(true)
        getAccount(1, upFields, value)
            .then((res) => {
                if (res) {
                    setDate(false)
                    setSearch(true)
                    setFlag(false)
                    setLoading(false)
                    setTableLoading(false)
                } else {
                    setDate(false)
                    setSearch(false)
                    setFlag(false)
                    setTableLoading(false)
                    setLoading(false)
                }
            })

    }

    function renderClear() {
        return (
            <Col xs={12} md={12} lg={6} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin   laptop ml-0 "
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={() => onSearch()}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline  laptop"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setSearch(false)
                        clearAccounts();
                        setNameLoad(true)
                        setEmailLoad(true)
                        getAccountList('', false, fields.account_type).then(() => {
                            setEmailLoad(false)
                            setNameLoad(false)
                        })
                        const payload = {
                            name: "",
                            email: "",
                            status: 'active',
                            country: "",
                            state: "",
                            city: "",
                            show_delete_user: false,
                            account_type: "brand",
                            app: true,
                            user_id: '',
                            orderBy: -1,
                            sort: "pixel_id",
                            agreement: 'accepted',
                            ecom: 'all',
                            ecom_status: 'connected',
                            stripe_type: 'all',
                            stripe_status: 'connected',
                            ig_status: 'all',
                        }
                        setFields(payload)
                        setCountryName("")
                        setStateName("")
                        setName("")
                        setEmail("")
                        setLoading(true)
                        setValue([moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD"),])
                        const upFields = { ...payload }
                        upFields.is_active = true

                        setTableLoading(true)
                        getAccount(1, upFields, [moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
                            .then((res) => {
                                if (res) {

                                    setSearch(true)
                                    setLoading(false)
                                    setTableLoading(false)
                                } else {
                                    setSearch(false)
                                    setTableLoading(false)
                                    setLoading(false)
                                }
                            })
                    }}
                >
                    Clear
                </Button>
            </Col>
        )
    }

    useEffect(() => {
        setNameLoad(true)
        setEmailLoad(true)
        getAccountList('', false, fields.account_type).then(() => {
            setEmailLoad(false)
            setNameLoad(false)
        })
    }, [])

    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val, type) => {
        getAccountList(val, false, fields.account_type, type).then(() => {
            setEmailLoad(false)
            setNameLoad(false)
        })
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    function renderField() {
        let filter = columns.filter(f => f.field !== "version")
        return filter.map((m, i) => {
            if (m.field !== "sNo") return <MenuItem key={i} value={m.field} >{m.headerName}</MenuItem>
        })
    }
    useEffect(() => {
        if (flag) {
            onSearch()
        }
    }, [flag])
    return (
        <React.Fragment>
            <div className='container-fluid mb-3 pl-0 mt-3'>

                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Brands
                        </Typography>
                    </Col>
                    <Col xs className="mb-3 text-right">
                        <Button
                            onClick={() => {
                                history.push('/brand/add')
                            }}
                            className='btn-width'
                            startIcon={<AddIcon />}
                            disableElevation
                            variant="contained">
                            Add Brand
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} >
                        <DateBtn value={value} setValue={(data) => {
                            setValue(data)
                        }} setDate={setDate} />
                        <div className="pl-15 btn-div mb-3">
                            <button
                                className={fields.status === 'active' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'active', show_delete_user: true, agreement: 'accepted', stripe_status: 'connected', ecom_status: 'connected', app: true })
                                    setFlag(true)
                                }}
                            >
                                Active
                            </button>
                            <button
                                className={fields.status === 'incomplete' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'incomplete', show_delete_user: true, agreement: 'all', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    setFlag(true)
                                }}
                            >
                                Incomplete
                            </button>
                            <button
                                className={fields.status === 'new' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'new', app: 'all', agreement: 'all', stripe_status: 'all', ecom_status: 'all' })
                                    setFlag(true)
                                }}
                            >
                                New
                            </button>
                            <button
                                className={fields.status === 'deactive' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'deactive', show_delete_user: true, agreement: 'all', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    setFlag(true)
                                }}
                            >
                                Deactive
                            </button>
                        </div>
                    </Col>
                    {/* <Col xs={12} md={12} lg={6} xl={6} >
                        <div className="pl-15 btn-div mb-3">
                            <button
                                className={fields.status === 'active' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'active', show_delete_user: true, agreement: 'accepted', stripe_status: 'connected', ecom_status: 'connected', app: true })
                                    setFlag(true)
                                }}
                            >
                                Active
                            </button>
                            <button
                                className={fields.status === 'incomplete' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'incomplete', show_delete_user: true, agreement: 'accepted', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    setFlag(true)
                                }}
                            >
                                Incomplete
                            </button>
                            <button
                                className={fields.status === 'new' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'new', app: 'all', agreement: 'all', stripe_status: 'all', ecom_status: 'all' })
                                    setFlag(true)
                                }}
                            >
                                New
                            </button>
                            <button
                                className={fields.status === 'deactive' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'deactive', show_delete_user: true, agreement: 'all', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    setFlag(true)
                                }}
                            >
                                Deactive
                            </button>
                        </div>
                    </Col> */}
                </Row>



                <Row className="account-main-row pl-15">
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}

                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl
                            className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Account Type</InputLabel>
                            <Select
                                disabled
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select1"
                                value={"brand"}
                                label="Account Type"
                                onChange={(e) => {
                                    setFields({ ...fields, account_type: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"influencer"}>Influencer</MenuItem>
                                <MenuItem value={"brand"}>Brand</MenuItem>
                                <MenuItem value={"customer"}>Customer</MenuItem>
                            </Select>
                        </FormControl>
                    </Col> */}
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={nameLoad ? [] : accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` })) : []}
                            onChange={(e, data) => {
                                setFields({ ...fields, user_id: data?._id })
                                setName(data.label)
                            }}
                            value={name}
                            renderInput={(params) => <TextField {...params} label="Name" onChange={(e) => {
                                if (e.target.value !== '') {
                                    setName(e.target.value)
                                    setFields({ ...fields, name: e.target.value })
                                    setNameLoad(true)
                                    optimizedFn(e.target.value)
                                } else {
                                    setName('')
                                    setNameLoad(true)
                                    setFields({ ...fields, user_id: '', name: '' })
                                    optimizedFn(e.target.value)
                                }
                            }} />}
                        />
                    </Col>
                    {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.email}
                            onChange={e => setFields({ ...fields, email: e.target.value })}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col> */}
                    {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={countryName}
                            options={countries.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.code1) {
                                    setCountryName(data.name)
                                    setFields({ ...fields, country: data.code1 })
                                    getStates(data.code1)
                                        .then((res) => {
                                            if (res.success) {
                                                setStates(res?.message)
                                            }
                                        })
                                } else {
                                    setFields({ ...fields, country: "", state: "", city: "" })
                                    setCountryName("")
                                    setStateName("")
                                    setStates([])
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => (
                                <TextField
                                    // error={(submit && !countryName)} 
                                    {...params} label="Country" />
                            )}
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            disabled={!fields.country}
                            disablePortal
                            value={stateName}
                            id="combo-box-demo"
                            options={states.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.isoCode) {
                                    setStateName(data.name)
                                    setFields({ ...fields, state: data.isoCode })
                                    getCity({
                                        country_code: fields.country,
                                        state_code: data.isoCode
                                    }).then(res => {
                                        setCities(res?.message)
                                    })
                                } else {
                                    setFields({ ...fields, state: "", city: "" })
                                    setStateName("")
                                    setCities([])
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => <TextField {...params} label="State" />}
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            disabled={(!fields.country || !fields.state)}
                            disablePortal
                            value={fields.city}
                            id="combo-box-demo"
                            options={cities.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.name) {
                                    setFields({ ...fields, city: data.name })
                                } else {
                                    setFields({ ...fields, city: "" })
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => <TextField {...params} label="City" />}
                        />
                    </Col> */}
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.status}

                                label="Status"
                                onChange={(e) => {
                                    let val = e.target.value
                                    if (val === "new") {
                                        setFields({ ...fields, status: e.target.value, app: 'all', agreement: 'all', stripe_status: 'all', ecom_status: 'all' })
                                    } else if (val === "active") {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: true, agreement: 'accepted', stripe_status: 'connected', ecom_status: 'connected', app: true })
                                    } else if (val === "incomplete") {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: true, agreement: 'all', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    } else if (val === "deactive") {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: true, agreement: 'all', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    } else {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: false })
                                    }
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={'active'}>Active</MenuItem>
                                <MenuItem value={'incomplete'}>Incomplete</MenuItem>
                                <MenuItem value={'new'}>New</MenuItem>
                                <MenuItem value={'deactive'}>Deactive</MenuItem>
                                <MenuItem value={'deleted'}>Deleted</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Public</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.app}

                                label="Public"
                                onChange={(e) => {
                                    setFields({ ...fields, app: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>On</MenuItem>
                                <MenuItem value={false}>Off</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Agreement</InputLabel>
                            <Select

                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.agreement}

                                label="Agreement"
                                onChange={(e) => {
                                    setFields({ ...fields, agreement: e.target.value, })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"accepted"}>Accepted</MenuItem>
                                <MenuItem value={"waiting"}>Waiting</MenuItem>
                                <MenuItem value={"rejected"}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Stripe Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.stripe_type}
                                label="Stripe Type"
                                onChange={(e) => {
                                    setFields({ ...fields, stripe_type: e.target.value, })
                                }}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'stripe'}>Stripe</MenuItem>
                                <MenuItem value={'stripeconnect'}>Stripe Connect</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Stripe Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.stripe_status}
                                label="Stripe Status"
                                onChange={(e) => {
                                    setFields({ ...fields, stripe_status: e.target.value, })
                                }}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'connected'}>Connected</MenuItem>
                                <MenuItem value={'notconnected'}>Not Connected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Ecom</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.ecom}
                                label="Ecom"
                                onChange={(e) => {
                                    setFields({ ...fields, ecom: e.target.value, })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"shopify"}>Shopify</MenuItem>
                                <MenuItem value={"salesforce"}>Salesforce</MenuItem>
                                <MenuItem value={"magento"}>Magento</MenuItem>
                                <MenuItem value={"sellercenter"}>Seller Center</MenuItem>
                                <MenuItem value={"squarespace"}>Squarespace</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Ecom Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.ecom_status}
                                label="Ecom Status"
                                onChange={(e) => {
                                    setFields({ ...fields, ecom_status: e.target.value, })
                                }}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'connected'}>Connected</MenuItem>
                                <MenuItem value={'notconnected'}>Not Connected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Instagram Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.ig_status}
                                label="Instagram Status"
                                onChange={(e) => {
                                    setFields({ ...fields, ig_status: e.target.value, })
                                }}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={true}>Connected</MenuItem>
                                <MenuItem value={false}>Not Connected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className='field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                {renderField()}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className=' field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    {renderClear()}
                </Row>
                <Divider className="ml-15" />
                <DataTable
                    date={date}
                    type="brand"
                    id={Object.values(rest?.location)[1]}
                    tableLoading={tableLoading}
                    nowGet={(page) => {
                        setTableLoading(true)
                        const upFields = { ...fields }
                        if (fields.status == 'deactive') {
                            upFields.is_active = false
                            // upFields.is_deleted = false
                        } else if (fields.status == 'active') {
                            upFields.is_active = true
                        } else if (fields.status == 'incomplete') {
                            upFields.is_active = true
                        } else if (fields.status == 'new') {
                            upFields.is_active = 'new'
                        } else if (fields.status == 'deleted') {
                            // upFields.is_active = true
                            // upFields.show_delete_user = true
                            // upFields.is_deleted = true
                        } else {
                            upFields.is_active = undefined;
                        }
                        getAccount(page, upFields, value).then(() => setTableLoading(false))
                    }}
                    accounts={accounts}
                    search={search}
                    loadTable={loading}
                />
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = ({ accounts, packages, countries, accountList }) => {
    return { accounts, packages, countries, accountList };
};

export default connect(mapStateToProps, { ...accountActions, ...packActions, ...countryActions })(Accounts);
