import React, { useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';

import { Col, Row } from "react-bootstrap";
import { Button, Typography, Divider, TextField, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Skeleton from '../../reUsable/skeleton';
import "../accountMain/components/sale.css"
import *  as feedActions from 'store/actions/feedAction'
import moment from 'moment';

import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from "./RegisterTable";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import DateBtn from "reUsable/DateBtn";


function UserRegisterStats({ getRegisterStats, registerStats }) {
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState(false)
    const [value, setValue] = useState([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
    const [accountType, setAccountType] = useState('all')
    useEffect(() => { getData(1, value, accountType) }, [])

    function handleSearch(type) {
        if (type === "refresh") {
            setValue([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")])
            setAccountType('all')
            getData(1, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")], 'all')
        } else {
            getData(1, value, accountType)
        }
    }

    const getData = (page = 1, data, type) => {
        if (page === 1) {
            setLoading(true)
        }
        let obj = { fromDate: data[0], toDate: data[1], account_type: type }
        getRegisterStats(obj, page).finally(() => {
            setDate(false)
            setLoading(false)
        })
    }
    useEffect(() => {
        if (date) {
            getData(1, value, accountType)
        }
    }, [date])

    const stats = useMemo(() => {
        if (registerStats.success) {
            return { ...registerStats?.data?.[0], summary: registerStats?.summaryData?.[0] }
        } else {
            return null
        }
    }, [registerStats])
    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xl className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Link in Bio Stats
                        </Typography>

                    </Col>
                </Row>
                <DateBtn value={value} setValue={setValue} setDate={setDate} />
                <Row className="account-main-row pl-15">

                    <Col xl={3} lg={12} className="mb-20">

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Select Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={accountType}
                                label="Select Type"
                                onChange={(e) => {
                                    setAccountType(e.target.value)
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"brand"}>Brand</MenuItem>
                                <MenuItem value={"influencer"} >Influencer</MenuItem>
                            </Select>
                        </FormControl>

                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width laptop ml-0"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale ml-15" />
                <div style={{ height: 575 }} className="pl-3">
                    {
                        loading ? <Skeleton /> :
                            <>
                                <DataTable
                                    nowGet={(page) => {
                                        getData(page, value, accountType)
                                    }}
                                    data={stats}
                                />

                            </>
                    }
                </div>
            </div>

        </React.Fragment>
    )
}
function mapStateToProps({ registerStats, }) {
    return { registerStats, }
}
export default connect(mapStateToProps, feedActions)(UserRegisterStats)