import { GET_PROMOCODE_DETAILS } from "store/actions/type";

const initialState={};

export default function promocodeDetails(state=initialState,action){
    switch(action.type){
        case GET_PROMOCODE_DETAILS:
            return action.payload;
        default:
            return state
    }
}