import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as accountActions from "../../../store/actions/account"
import * as packageActions from "../../../store/actions/package"
import { Row, Col } from "react-bootstrap"
import SkeletonLoader from "reUsable/skeleton";
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { toast } from "react-toastify";
import ConfirmModal from 'reUsable/ConfirmModal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Input, InputAdornment, TextField } from '@mui/material';
import { WithContext as ReactTags } from 'react-tag-input';
import SaveModal from "../../../reUsable/SaveModal";
const KeyCodes = {
    comma: 188,
    enter: [10, 13],
}

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

function Package({ getDetails, accountDetail, currentUser, nowGet, updateSearchTagsBrand }) {

    const [loading, setLoading] = useState(true);
    const [confirmModal, setConfirmModal] = useState(false)
    const [tags, setTags] = useState([])
    const [rank, setRank] = useState(null)
    useEffect(() => {
        getDetails(currentUser).then(() => setLoading(false))
    }, [])
    const searchTags = useMemo(() => {
        if (accountDetail?.userDetails?.searching_keywords) {
            if (accountDetail?.userDetails?.searching_keywords?.split(', ')?.length > 0) {
                setTags(accountDetail?.userDetails?.searching_keywords?.split(', ')?.map(item => ({ id: item, text: item })))
            }
        } else {
            setTags([])
        }
        if (accountDetail?.userDetails?.searching_rank) {
            setRank(accountDetail?.userDetails?.searching_rank)
        } else {

            setRank(null)
        }
    }, [accountDetail])

    function handleDelete(i) {
        const updatedTags = tags.filter((tag, index) => index !== i)
        setTags(updatedTags)
    }

    function handleAddition(tag) {
        setTags((prev) => [...prev, tag])
    }
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
    };
    function save() {
        const data = {
            brand_id: currentUser,
            searching_keywords: tags?.map(item => item.text).join(', ')
        }
        if (rank || rank === 0) {
            data.searching_rank = Number(rank)
        }
        updateSearchTagsBrand(data).finally(() => {
            setConfirmModal(false)
            getDetails(currentUser)
        })
    }
    return (
        <React.Fragment>
            {loading ? (
                <SkeletonLoader />
            ) :
                <>     <SaveModal
                    visible={confirmModal}
                    title={`Are you sure you want to save these changes?`}
                    close={() => setConfirmModal(false)}
                    type="status"
                    call={save}
                />
                    <Row className='align-items-center'>
                        <Col xl={5} lg={6}>
                            <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                                <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                                    Search Tags
                                </Typography>
                                <Divider className='mb-3' />
                                <CardContent>
                                    <FormControl className="field-style" size="small">
                                        <TextField
                                            size="small"
                                            value={rank}
                                            onChange={e => {
                                                let val = e.target.value;
                                                if (val >= 0) {
                                                    setRank(val)
                                                }
                                            }}
                                            id="outlined-basic"
                                            type='number'
                                            label="Rank"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0
                                                },
                                            }}
                                            variant="outlined"
                                            className="field-style mb-3"
                                        />
                                    </FormControl>
                                    <FormControl className="field-style" size="small">
                                        <ReactTags
                                            allowDeleteFromEmptyInput={false}
                                            tags={tags}
                                            autofocus={false}
                                            classNames={{
                                                tags: "tagsClass",
                                                tag: 'tagClass',
                                                remove: 'removeClass',
                                                tagInput: 'tagInputClass',
                                                tagInputField: "inputFieldClass"
                                            }}
                                            style={{ height: "40px !important" }}
                                            placeholder="Press enter or comma to add services"
                                            inputFieldPosition="top"
                                            handleDelete={handleDelete}
                                            handleDrag={handleDrag}
                                            handleAddition={handleAddition}
                                            delimiters={delimiters}
                                        />
                                    </FormControl>
                                    <div style={{ width: "100%", display: "flex", }}>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            className="btn-gen-margin btn-width ml-0"
                                            color="primary"
                                            disableElevation
                                            onClick={() => {
                                                setConfirmModal(true)
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </React.Fragment >
    );
}

function mapStateToProps({ accountDetail, packages }) {
    return { accountDetail, packages }
}

export default connect(mapStateToProps, { ...accountActions, ...packageActions })(Package)