import {CLEAR_ACCOUNTS, GET_ACCOUNTS} from "../../actions/type";

const initialState={};

export default function accounts(state=initialState,action){
    switch(action.type){
        case GET_ACCOUNTS:
            return action.payload;
        case CLEAR_ACCOUNTS:
            return action.payload;
        default:
            return state
    }
}