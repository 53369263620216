import {CHANGE_STATUS, CLEAR_CAMPAIGN, DELETE_CAMPAIGN, GET_BRAND_CAMPAIGN, GET_CAMPAIGN} from "../../actions/type";

const initialState={
    data:[],
    count:0
};

export default function brandCampaigns(state=initialState,action){
    switch(action.type){
        case GET_BRAND_CAMPAIGN:
            return action.payload
        // case CLEAR_CAMPAIGN:
        //     return {data:[],count:0}
        // case DELETE_CAMPAIGN:
        //     const data=[...state.data].filter(item=>item._id!=action.payload)
        //     return {...state,data}
        // case CHANGE_STATUS:
        //     const updatedData=[...state.data].map((item)=>{
        //         if(item._id==action.payload._id){
        //             return {
        //                 ...item,
        //                 is_active:action.payload.is_active
        //             }
        //         }
        //         return item
        //     })
        //     return {...state,data:updatedData}
        default:
            return state
    }
}