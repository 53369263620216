import konnect from "./axios"
import { CLEAR_ACCOUNTS, GET_ACCOUNTS, GET_ACCOUNT_DETAIL, GET_ACCOUNT_LIST, GET_BALANCE, ACCOUNT_CATEGORY, PURCHASER, REFERRER, AGENCIES, AGENCIESDETAIL } from "./type"
const codes = [
    { id: '+1', label: 'United States' },
    { id: '+44', label: 'United Kingdom' },
    { id: '+91', label: 'India' },
    { id: '+92', label: 'Pakistan' },
    { id: '+61', label: 'Australia' },
    { id: '+81', label: 'Japan' },
    { id: '+49', label: 'Germany' },
    { id: '+33', label: 'France' },
    { id: '+86', label: 'China' },
    { id: '+55', label: 'Brazil' },
    { id: '+39', label: 'Italy' },
    { id: '+34', label: 'Spain' },
    { id: '+7', label: 'Russia' },
    { id: '+27', label: 'South Africa' },
    { id: '+82', label: 'South Korea' },
    { id: '+966', label: 'Saudi Arabia' },
    { id: '+971', label: 'United Arab Emirates' },
    { id: '+234', label: 'Nigeria' },
    { id: '+20', label: 'Egypt' },
    { id: '+353', label: 'Ireland' },
    { id: '+64', label: 'New Zealand' },
    { id: '+98', label: 'Iran' },
    { id: '+52', label: 'Mexico' },
    { id: '+60', label: 'Malaysia' },
    { id: '+66', label: 'Thailand' },
    { id: '+90', label: 'Turkey' },
    { id: '+62', label: 'Indonesia' },
    { id: '+351', label: 'Portugal' },
    { id: '+31', label: 'Netherlands' },
    { id: '+45', label: 'Denmark' },
    { id: '+46', label: 'Sweden' },
    { id: '+48', label: 'Poland' },
    { id: '+43', label: 'Austria' },
    { id: '+41', label: 'Switzerland' },
    { id: '+30', label: 'Greece' },
    { id: '+359', label: 'Bulgaria' },
    { id: '+36', label: 'Hungary' },
    { id: '+972', label: 'Israel' },
    { id: '+63', label: 'Philippines' },
    { id: '+57', label: 'Colombia' },
    { id: '+505', label: 'Nicaragua' },
    { id: '+94', label: 'Sri Lanka' },
    { id: '+372', label: 'Estonia' },
    { id: '+380', label: 'Ukraine' },
    { id: '+56', label: 'Chile' },
    { id: '+386', label: 'Slovenia' },
    { id: '+420', label: 'Czech Republic' },
    { id: '+32', label: 'Belgium' },
    { id: '+258', label: 'Mozambique' },
    { id: '+233', label: 'Ghana' },
    { id: '+48', label: 'Poland' },
    { id: '+43', label: 'Austria' },
    { id: '+41', label: 'Switzerland' },
    { id: '+84', label: 'Vietnam' },
    { id: '+880', label: 'Bangladesh' },
    { id: '+507', label: 'Panama' },
    { id: '+212', label: 'Morocco' },
    { id: '+591', label: 'Bolivia' },
    { id: '+963', label: 'Syria' },
    { id: '+505', label: 'Nicaragua' },
    { id: '+355', label: 'Albania' },
    { id: '+268', label: 'Eswatini' },
    { id: '+506', label: 'Costa Rica' },
    { id: '+54', label: 'Argentina' },
    { id: '+598', label: 'Uruguay' },
    { id: '+52', label: 'Mexico' },
    { id: '+244', label: 'Angola' }
];

export const getAccount = (page, data, date) => async (dispatch) => {
    const updatedData = { ...data }
    if (updatedData?.is_login_permission == "") updatedData.is_login_permission = undefined
    if (updatedData?.is_login_permission == "false") updatedData.is_login_permission = false
    if (updatedData?.account_type == "all") updatedData.account_type = ""
    if (updatedData?.package_id == "all") updatedData.package_id = ""
    if (updatedData?.is_login_permission == "all") updatedData.is_login_permission = ""
    if (updatedData?.status == "all") updatedData.status = ""
    if (updatedData?.status == 1) { updatedData.is_active = true; updatedData.is_deleted = false }
    if (updatedData?.status == 2) { updatedData.is_active = false; updatedData.is_deleted = false }
    if (updatedData?.status == 3) { updatedData.is_deleted = true }
    if (updatedData?.status == 'deactive') { updatedData.is_active = false }
    if (updatedData?.device === 'all') { updatedData.device = '' }
    if (updatedData?.agreement === 'all') { updatedData.agreement = '' }
    if (updatedData?.ecom === 'all') { updatedData.ecom = '' }
    if (updatedData?.stripe_status === 'all') { updatedData.stripe_status = '' }
    if (updatedData?.ecom_status === 'all') { updatedData.ecom_status = '' }
    if (updatedData?.stripe_type === 'all') { updatedData.stripe_type = '' }
    if (updatedData?.is_ai_search === 'all') { updatedData.is_ai_search = '' }
    if (updatedData?.email_verified === 'all') { updatedData.email_verified = '' }
    if (updatedData?.shipping_addresses === 'all') { updatedData.shipping_addresses = '' }
    if (updatedData?.opt_verified === 'all') { updatedData.opt_verified = '' }
    if (updatedData?.paypal_email === 'all') { updatedData.paypal_email = '' }
    if (updatedData?.ig_status === 'all') { updatedData.ig_status = '' }
    if (date) {
        updatedData.from = date[0]
        updatedData.to = date[1]
    }
    if (updatedData?.phoneCode) {
        if (updatedData.phoneCode.length > 0) {
            updatedData.phoneCode = updatedData.phoneCode.map(code => code.id)
        }
    }
    // if (updatedData.phoneCode.length > 0) {
    //     updatedData.phoneCode = updatedData.phoneCode.map(code => code.id)
    // }
    try {

        const res = await konnect.post('/v1/admin/crm/getusers', updatedData, {
            params: {
                page: page,
                limit: 10
            }
        })
        dispatch({
            type: GET_ACCOUNTS,
            payload: res.data?.data
        })
        return res.data.data

    } catch (err) {
        console.log(err.response)
        return err.response
    }
}

export const clearAccounts = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ACCOUNTS,
        payload: []
    })
}

export const getDetails = (_id, deleted) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/crm/getuserdetails', { _id, deleted })
        dispatch({
            type: GET_ACCOUNT_DETAIL,
            payload: res.data?.data
        })
        return res?.data?.data?.userDetails
    } catch (err) {
        console.log(err.response)
    }
}

export const changeActive = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/crm/updatestatus ', data)
    return res.data
}
export const updateBrandLogo = (id, file) => async (dispatch) => {
    const data = new FormData()
    data.append('brand_id', id)
    data.append('file', file)
    try {
        const res = await konnect.post('/v1/admin/banner/uploadBrandLogo', data)
        return res.data
    } catch (err) {
        console.log('err', err.response)
        return err.response
    }

}
export const changeAccountType = (user_id) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/crm/becomeShopper', { user_id })
    return res
}

export const updateAddon = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/updateProfileHashtagCategoryLimitByAdmin', data)
    return res
}

export const followerReq = (became_influencer, user_id) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/approvedRejectFollowerReqestByAdmin', { became_influencer, user_id })
    return res
}

export const getAccountList = (name, show_delete_user, account_type, type) => async (dispatch) => {
    let data = { show_delete_user, account_type, name, }
    if (account_type !== "brand") {
        if (account_type !== "brand" && type === "house") { data.is_house_influencer = true } else {
            data.is_house_influencer = false
        }
    }
    const res = await konnect.post('/v1/admin/filters/crm?page=1&limit=20', data)
    dispatch({
        type: GET_ACCOUNT_LIST,
        payload: res.data?.data
    })
    return res
}
export const getUsers = (name, show_delete_user, account_type, type) => async (dispatch) => {
    let data = { show_delete_user, account_type, is_active: true, name, is_deleted: false }
    if (account_type !== "brand") {
        if (account_type !== "brand" && type === "house") { data.is_house_influencer = true } else {
            data.is_house_influencer = false
        }
    }

    const res = await konnect.post('/v1/admin/filters/crm?page=1&limit=20', data)
    return res?.data
}
export const getLegProfile = (name, show_delete_user) => async (dispatch) => {
    if (name.length > 0) {
        let url = "/v1/admin/getfilteredAccountBrandInfluncerWise?page=1&limit=50"
        const res = await konnect.post(url, { name, show_delete_user: false, is_active: true });
        dispatch({
            type: GET_ACCOUNT_LIST,
            payload: res.data?.data
        })

        return res
    }

}

export const getBalance = (user_id, type) => async (dispatch) => {
    let url = '';
    if (type === "brand") url = '/v1/admin/report/brandBalanceDetails'
    if (type === "influencer") url = '/v1/admin/report/influencerBalanceDetails'
    if (type === "customer") url = '/v1/admin/report/customerBalanceDetails'
    // const url = type === "brand" ? '/v1/admin/report/balanceDetails' : '/v1/admin/report/influencerBalanceDetails'
    const res = await konnect.post(url, { user_id })
    dispatch({
        type: GET_BALANCE,
        payload: res?.data?.message
    })
    return res
}

export const addBalance = (data) => async (dispatch) => {
    const url = "/v1/admin/transaction/manuallyTransactionByAdmin"
    const res = await konnect.post(url, data)
    return res
}

export const getAccountByCategory = (page, data) => async (dispatch) => {
    const updatedData = { ...data }
    try {
        const res = await konnect.post('/v1/admin/banner/categoryWiseReturnBrand', updatedData, {
            params: {
                page: page,
                limit: 100
            }
        })
        dispatch({
            type: ACCOUNT_CATEGORY,
            payload: res.data?.getSummary
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const getBrandList = (name, show_delete_user, account_type) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/filters/crm?page=1&limit=20', { name, show_delete_user, account_type, is_active: true, featured: true })
    dispatch({
        type: GET_ACCOUNT_LIST,
        payload: res.data?.data
    })
    return res
}


export const getCustomer = (name) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/brand/getCustomers', { name })
        dispatch({
            type: PURCHASER,
            payload: res.data?.message
        })
    } catch (err) {
        console.log(err.response)
    }
}
export const getReferral = (name) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/brand/getReferers', { name })
        dispatch({
            type: REFERRER,
            payload: res.data?.message
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const getInfluencers = (id) => async (dispatch) => {
    let data = { show_delete_user: false, account_type: 'influencer', is_active: true, is_deleted: false, pixel_id: id ? Number(id) : '' }
    const res = await konnect.post('/v1/admin/filters/crm?page=1&limit=20', data)
    return res?.data
}

export const getBrand = (name, show_delete_user) => async (dispatch) => {
    let data = { show_delete_user, account_type: "brand", is_active: true, name, is_deleted: false }
    const res = await konnect.post('/v1/admin/filters/crm?page=1&limit=20', data)
    return res.data
}
export const getBrandToken = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/brand/getBrandToken', data)
        return res.data
    } catch (err) {
        return err.response.data
    }

}
export const changeBrandPassword = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/brand/changePassword', data)
        return res.data
    } catch (err) {
        return err.response.data
    }

}
export const getBrandDiscounts = (brand_id) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/crm/getDiscounts', { brand_id })
        return res.data
    } catch (err) {
        return err.response.data
    }

}

export const getAccountBalance = (brand_id) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/brand/checkAccountBalance`, { brand_id })
        return res?.data
    } catch (err) {
        return err?.response?.data
    }
}
export const sendEmail = (brand_id) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/brand/balanceReminder`, { brand_id })
        return res?.data
    } catch (err) {
        return err?.response?.data
    }
}

export const setBrandRank = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/brand/setBrandRank', data)
        return res.data
    } catch (err) {
        return err.response.data
    }

}

export const getAgency = (page, data, date) => async (dispatch) => {
    const updated = {
        ...data,
        is_active: data?.is_active === "all" ? '' : data?.is_active,
        agreement_status: data?.agreement_status === "all" ? '' : data?.agreement_status,
        pixel_id: data?.pixel_id === "" ? '' : Number(data?.pixel_id)
    }
    updated.from = date[0]
    updated.to = date[1]
    try {
        const res = await konnect.post('/v1/admin/agency/getAgency', updated, {
            params: {
                page: page,
                limit: 10
            }
        })
        dispatch({
            type: AGENCIES,
            payload: res.data?.data
        })
        return res.data.data

    } catch (err) {
        console.log(err)
        dispatch({
            type: AGENCIES,
            payload: []
        })
        return err.response
    }
}

export const getAgencyDetail = (_id) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/agency/getAgencyDetail', { _id })
        dispatch({
            type: AGENCIESDETAIL,
            payload: res.data
        })
        // return res?.data?.data?.userDetails
    } catch (err) {
        console.log(err.response)
    }
}

export const getAgencyList = (name) => async (dispatch) => {

    try {
        const res = await konnect.post('/v1/admin/agency/getAgencyList?page=1&limit=20', { company_name: name })
        return res.data
    } catch (err) {
        console.log("err", err)
    }

}
export const updateAgency = (_id, is_active = false) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/agency/activeDeactiveAgenacy', { _id, is_active })
        return res?.data
    } catch (err) {
        console.log("err", err)
    }
}


export const getAccountCSV = (data, date, limit) => async (dispatch) => {
    const updatedData = { ...data }
    if (updatedData?.is_login_permission == "") updatedData.is_login_permission = undefined
    if (updatedData?.is_login_permission == "false") updatedData.is_login_permission = false
    if (updatedData?.account_type == "all") updatedData.account_type = ""
    if (updatedData?.package_id == "all") updatedData.package_id = ""
    if (updatedData?.is_login_permission == "all") updatedData.is_login_permission = ""
    if (updatedData?.status == "all") updatedData.status = ""
    if (updatedData?.status == 1) { updatedData.is_active = true; updatedData.is_deleted = false }
    if (updatedData?.status == 2) { updatedData.is_active = false; updatedData.is_deleted = false }
    if (updatedData?.status == 3) { updatedData.is_deleted = true }
    if (updatedData?.status == 'deactive') { updatedData.is_active = false }
    if (updatedData?.device === 'all') { updatedData.device = '' }
    if (updatedData?.agreement === 'all') { updatedData.agreement = '' }
    if (updatedData?.ecom === 'all') { updatedData.ecom = '' }
    if (updatedData?.stripe_status === 'all') { updatedData.stripe_status = '' }
    if (updatedData?.ecom_status === 'all') { updatedData.ecom_status = '' }
    if (updatedData?.stripe_type === 'all') { updatedData.stripe_type = '' }
    if (updatedData?.is_ai_search === 'all') { updatedData.is_ai_search = '' }
    if (updatedData?.email_verified === 'all') { updatedData.email_verified = '' }
    if (updatedData?.shipping_addresses === 'all') { updatedData.shipping_addresses = '' }
    if (updatedData?.opt_verified === 'all') { updatedData.opt_verified = '' }
    if (updatedData?.paypal_email === 'all') { updatedData.paypal_email = '' }
    if (updatedData?.ig_status === 'all') { updatedData.ig_status = '' }
    if (updatedData?.phoneCode) {
        if (updatedData.phoneCode.length > 0) {
            updatedData.phoneCode = updatedData.phoneCode.map(code => code.id)
        }
    }
    if (date) {
        updatedData.from = date[0]
        updatedData.to = date[1]

    }
    try {

        const res = await konnect.post('/v1/admin/crm/getusers', updatedData, {
            params: {
                page: 1,
                limit
            }
        })
        return res.data.data

    } catch (err) {
        console.log(err.response)
        return err.response
    }
}

export const updateSearchTagsBrand = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/crm/addSearchingKeywords', data)
        return res?.data
    } catch (err) {
        console.log(err.response)
    }
}