import { GET_ALL_SALES } from "../../actions/type";

export default function allSales(state = {}, action) {
    switch (action.type) {
        case GET_ALL_SALES:
            {
                // let obj = {
                //     total_records: action.payload.total_records,
                //     data: action.payload.data.filter(f => f?.influencer_name !== "KonnectBio")
                // }
                // return obj
                return action.payload
            }
        default:
            return state
    }
}