import React, { useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'

function DataTable({ nowGet, data, summary }) {
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const feedCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "pixel_id", headerName: "PID", width: 120, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "phone", headerName: "Phone", width: 140, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "name", headerName: "User Name", width: 180, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "email", headerName: "Email", width: 250, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "login_data", headerName: "Login", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "duration", headerName: "Watch Time", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_views", headerName: "Views", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_shares", headerName: "Share", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_likes", headerName: "Likes", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "following_data", headerName: "Following", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_saves", headerName: "Favorites", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value ? value : value === 0 ? 0 : <span style={{ color: "#bebebe" }}>Empty</span>}</span> },
        { field: "purchase", headerName: "Purchases", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{numeral(value).format("$0,0.00", Math.floor)}</span> },
        { field: "total_comments", headerName: "Comments", width: 90, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
    ]
    const [sum, setSum] = useState(null)

    const feeds = useMemo(() => {
        if (data?.success) {
            if (data?.summary) {
                setSum(data?.summary)
                setPageLoad(false)
                return { total: data?.total, data: data?.data }
            }
        }
        if (data?.success) {
            setPageLoad(false)
            return data?.getUserAnalyticsevent ? data?.getUserAnalyticsevent?.[0] : data?.getUserAnalyticsreviews?.[0]
        }
    }, [data])

    function formatDuration(seconds) {
        if (seconds === 0) {
            return 0
        } else if (seconds <= 60) {
            return `${(seconds / 60).toFixed(1)} min`
        } else {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            if (hours) {
                return `${hours} ${hours > 1 ? 'hours' : 'hour'} ${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            } else {
                return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            }
        }

    }
    return (
        <>
            <DataGrid
                showCellRightBorder={true}
                rowHeight={35}
                disableColumnFilter={true}
                density="compact"
                loading={pageLoad}
                components={{ NoRowsOverlay: NoRows }}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                rows={
                    feeds?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 15) - 14),
                        duration: formatDuration(item?.total_duration)


                    }))
                }
                columns={feedCol}
                rowCount={Object.keys(feeds?.data).length !== 0 ? feeds?.total : 0}
                pagination
                pageSize={15}
                rowsPerPageOptions={[15]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    if (sum) {
                        nowGet(newPage + 1, true);
                    } else {
                        nowGet(newPage + 1);
                    }
                }}

            />
            <div className='sumContainer-height-235  pb-2'  >
                <div className='sumContainer' style={{ background: "#fff" }}>
                    <div className='sumTitle'>Summary</div>
                    <div className='sumBody'>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Views</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalViews : summary?.totalViews).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Shares</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalShares : summary?.totalShares).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Share %</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalShares / sum?.totalViews : summary?.totalShares / summary?.totalViews).format("0.0%")}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Likes</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalLikes : summary?.totalLikes).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Following</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalFollowing : summary?.totalFollowing).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Favorites</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalSaves : summary?.totalSaves).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Purchases</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalPurchases : summary?.totalPurchases).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow' style={{ borderBottom: "none" }}>
                            <div className='sumBodyTitle'>Total Comments</div>
                            <div className='sumBodyvalue'>{numeral(sum ? sum?.totalComments : summary?.totalComments).format("0,000,000", Math.floor)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default React.memo(DataTable)