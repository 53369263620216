import {GET_BRAND_AND_INSTAGRAM} from "../../actions/type";

const initialState=[]

export default function brandAndInstagram(state=initialState,action){
    switch(action.type){
        case GET_BRAND_AND_INSTAGRAM:
            return action.payload;;
        default:
            return state
    }
}