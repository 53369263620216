import { GET_NOTIFICATIONS_LIST } from "../../actions/type";

const initialState = [];

export default function notificationRed(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_LIST:
      return action.payload;
    default:
      return state;
  }
}
