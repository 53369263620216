import { TRANSACTION_TYPE } from "../../actions/type";

const initialState = [];

export default function transactionType(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_TYPE:
            return action.payload;
        default:
            return state
    }
}