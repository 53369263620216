
import React, { useEffect, useState } from 'react'
import { Typography, Divider, Box, Modal, IconButton } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import CancelIcon from '@mui/icons-material/Cancel';
import { connect } from "react-redux"
import * as salesActions from "../../../store/actions/sales"
import moment from 'moment';
import { setDate } from 'date-fns';
import numeral from 'numeral';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function OrderModal({ visible, close, data, type, user, orderDetail }) {
    const [fields, setFields] = useState(null);
    const [load, setLoad] = useState(true)
    const [details, setDetails] = useState(null)
    useEffect(() => {
        if (data) {
            let newData = {}
            setLoad(true)
            if (type === "referral") {
                if (data.sales_tracker_id) {
                    newData.id = data?.sales_tracker_id
                } else {
                    newData.id = data?._id
                }
                newData.event = data?.event
                newData.brand_id = user
            }
            if (type === "orders") {
                if (data.sales_tracker_id) {
                    newData.sales_tracker_id = data?.sales_tracker_id
                } else {
                    newData.sales_id = data?._id
                }
                newData.brand_id = user
            }
            if (type === "purchase") {
                if (data.sales_tracker_id) {
                    newData.sales_tracker_id = data?.sales_tracker_id
                } else {
                    newData.sales_id = data?._id
                }
                newData.brand_id = user
            }
            orderDetail(newData, type).then((res) => {
                if (res?.success) {
                    if (res?.message) {
                        const result = dataPattern(res?.message)
                        setDetails(result)
                        setFields({ order_detail: res?.message?.order_detail, order_return_detail: res?.message?.order_return_detail })
                        setLoad(false)
                    } else {
                        setDetails(null)
                        setLoad(false)
                    }
                } else {
                    setDetails(null)
                    setFields(null)
                    setLoad(false)
                }
            })
        }

        return () => {
            setDetails(null)
            setFields(null)
            setLoad(false)
        }

    }, [data])

    useEffect(() => {
        if (details) {
            //salesforce, magento, shopify, squarespace
        }
    }, [details])
    const computeTotalRefund = () => {
        const platform = details?.platform;
        switch (platform) {
            case 'shopify':
                // Shopify specific refund calculation (unchanged from previous code)
                return details?.returnDetails.reduce((total, orderReturnDetail) => {
                    const subtotalSum = orderReturnDetail.refund_line_items.reduce((subtotalSum, item) => subtotalSum + item.subtotal, 0);
                    const totalTaxSum = orderReturnDetail.refund_line_items.reduce((totalTaxSum, item) => totalTaxSum + (item.total_tax || 0), 0);
                    const shipTotal = orderReturnDetail.order_adjustments.some(item => item.kind === "shipping_refund") && orderReturnDetail.order_adjustments.reduce((totaltax, item) => totaltax + Math.abs(parseFloat(item?.amount || 0)), 0);
                    return total + subtotalSum + totalTaxSum + shipTotal;
                }, 0);

            case 'squarespace':
                const orderDetails = details?.returnDetails;
                const subtotalSum = orderDetails?.lineItems?.reduce((subtotalSum, item) => {
                    return subtotalSum + parseFloat(item?.unitPricePaid?.value);
                }, 0);
                const taxTotal = parseFloat(orderDetails?.taxTotal?.value) || 0;
                const shippingTotal = parseFloat(orderDetails?.shippingTotal?.value) || 0;
                const totalRefund = subtotalSum + taxTotal + shippingTotal;
                return totalRefund;

            case 'salesforce':
            // return purchaseSummary.payload.message.order_return_detail.reduce((total, orderReturnDetail) => {
            // 	const subtotalSum = orderReturnDetail.refund_line_items.reduce((subtotalSum, item) => subtotalSum + item.subtotal, 0);
            // 	const discountSum = orderReturnDetail.refund_line_items.reduce((discountSum, item) => discountSum - (item.discount || 0), 0);
            // 	const shippingRefund = orderReturnDetail.order_adjustments.some(item => item.kind === "shipping_refund") ? 5.00 : 0;  // example value
            // 	return total + subtotalSum + discountSum + shippingRefund;
            // }, 0);

            default:
                throw new Error('Unsupported platform');
        }
    };

    const dataPattern = ({ platform, order_detail, order_return_detail }) => {
        switch (platform) {
            case 'shopify': {
                let sumOfDiscountAmount = order_detail?.line_items.reduce((acc, item) => {
                    return acc + (item.total_discount > 0 ? Number(item?.price) * Number(item?.quantity) : 0)
                }, 0)

                const calculateDiscountPercent = () => {
                    return order_detail?.total_discounts > 0 ?
                        ((order_detail?.total_discounts / sumOfDiscountAmount).toFixed(2)) * 100
                        : 0
                }
                return {
                    platform: 'shopify',
                    orderDetails: {
                        ...order_detail,
                        orderNumber: order_detail?.order_number,
                        customerName: `${order_detail?.customer?.first_name} ${order_detail?.customer?.last_name}`,
                        orderDate: order_detail?.created_at,
                        lineItems: order_detail?.line_items,
                        subtotal: numeral(order_detail?.total_line_items_price).format("$0,0.0'"),
                        discount: order_detail?.total_discounts > 0 ? `-${numeral(order_detail?.total_discounts).format("$0,0.0'")}` : numeral(order_detail?.total_discounts).format("$0,0.0'"),
                        discountRate: calculateDiscountPercent(),
                        shipping: numeral(order_detail?.total_shipping_price_set?.presentment_money?.amount || 0).format("$0,0.0'"),
                        tax: numeral(order_detail?.total_tax).format("$0,0.0'"),
                        total: numeral(order_detail?.total_price).format("$0,0.0'"),
                    },
                    returnDetails: order_return_detail

                }
            }
            case 'squarespace': {
                return {
                    platform: 'squarespace',
                    orderDetails: {
                        ...order_detail,
                        orderNumber: order_detail?.orderNumber,
                        customerName: order_detail?.customer_details?.name,
                        orderDate: order_detail?.createdOn,
                        lineItems: order_detail?.lineItems.map(item => ({
                            ...item,
                            title: item.productName,
                            price: item.unitPricePaid.value,
                            fulfillment_status: order_detail?.fulfillmentStatus,
                        })),
                        subtotal: order_detail?.subtotal?.value,
                        discount: order_detail?.discountTotal?.value > 0 ? numeral(order_detail?.discountTotal?.value).format("$0,0.0") : numeral(0).format("$0,0.0"),
                        discountRate: order_detail?.discountTotal?.value > 0 ? ((order_detail?.discountTotal?.value / order_detail?.subtotal?.value).toFixed(2) * 100) : "",
                        shipping: numeral(order_detail?.shippingTotal?.value).format("$0,0.0'"),
                        tax: numeral(order_detail?.taxTotal?.value).format("$0,0.0'"),
                        total: numeral(Number(order_detail?.subtotal.value) + Number(order_detail?.taxTotal.value) + Number(order_detail?.shippingTotal.value)).format("$0,0.0'"),
                    },
                    returnDetails: {
                        ...order_return_detail?.[0],
                        lineItems: order_return_detail?.[0]?.lineItems?.map(item => ({
                            ...item,
                            created_at: order_return_detail[0].createdOn,
                            title: item?.productName,
                            price: item?.unitPricePaid?.value,
                            subtotal: item?.unitPricePaid?.value * item?.quantity
                        })),

                    },
                }
            }
            case 'salesforce': {
                return {
                    platform: 'salesforce',
                    orderDetails: {
                        ...order_detail,
                        orderNumber: order_detail?.order_no,
                        customerName: order_detail?.customer_name,
                        orderDate: order_detail?.creation_date,
                        lineItems: order_detail?.product_items?.map(item => ({
                            ...item,
                            sku: item.sku ? item.sku : "-",
                            title: item.product_name,
                            quantity: item.quantity,
                            price: item.price,
                            fulfillment_status: order_detail.fulfillment_status,
                        })),
                        subtotal: numeral(order_detail?.product_sub_total).format("$0,0.0'"),
                        discount: order_detail?.totalDiscounts > 0
                            ? `-${numeral(order_detail?.totalDiscounts).format("$0,0.0'")}`
                            : numeral(order_detail?.totalDiscounts).format("$0,0.0'")
                        ,
                        discountRate: order_detail?.totalDiscounts ? numeral(order_detail?.totalDiscounts / order_detail?.subtotalAmount).format("0%") : "",
                        shipping: numeral(order_detail?.shipping_total).format("$0,0.0'"),
                        tax: numeral(order_detail?.tax_total).format("$0,0.0'"),
                        total: numeral(
                            Number(order_detail?.product_sub_total) +
                            Number(order_detail?.tax_total) +
                            Number(order_detail?.shipping_total)
                        ).format("$0,0.0'"),
                    },
                    returnDetail: order_return_detail
                }
            }
            case 'magento': {
                return {
                    platform: "magento",
                    orderDetail: {
                        ...order_detail,
                    },
                    returnDetail: order_return_detail
                }
            }
            case 'sellercenter': {
                return {
                    platform: "sellercenter",
                    orderDetail: {
                        ...order_detail,
                    },
                    returnDetail: order_return_detail
                }
            }
        }
    }

    return (
        <Modal
            open={visible}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Box sx={style} className="deleteModal modal-spacing-new"
            // style={{ width: '52rem', maxHeight: "52rem", }}
            >
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                Order Detail
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={close}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row><Col xl={12} lg={12} className=""><Divider className='' /></Col></Row>
                <Row  >
                    <Col xl={12} lg={12} className="">
                        <div class="order-detail-title-area">
                            <div class="d-flex flex-column">
                                <div class="order-num">
                                    Order #
                                    <div class="order-name">{load ? "..." : details && details?.orderDetails?.orderNumber}</div>
                                    {
                                        !load &&
                                        details?.platform === "shopify" &&
                                        <>
                                            {fields?.order_detail?.cancel_reason && <div className='order-status status-error'>Cancelled</div>}
                                            {fields?.order_detail?.financial_status && <div className='order-status '>{fields?.order_detail?.financial_status}</div>}
                                            {fields?.order_detail?.fulfillment_status === null ? <div className='order-status '>Unfulfilled</div> : fields?.order_detail?.fulfillment_status === "partial" ? <div className='order-status status-success'>Partially Fulfilled</div> : <div className='order-status status-success'>Fulfilled</div>}
                                            {fields?.order_detail?.cancel_reason === null && fields?.order_return_detail?.length > 0 && <div className='order-status status-error'>Returned</div>}
                                        </>
                                    }
                                    {
                                        !load &&
                                        details?.platform === "squarespace" &&
                                        <>
                                            {
                                                fields?.order_detail?.fulfillmentStatus === "FULFILLED" ?
                                                    <div className='order-status status-success'>Fulfilled</div> :
                                                    fields?.order_detail?.fulfillmentStatus === "CANCELED" ?
                                                        <div className='order-status status-error'>{fields?.order_return_detail?.length > 0 ? "Returned" : "Unfulfilled"}</div> :
                                                        <div className='order-status'>Pending</div>
                                            }
                                        </>
                                    }
                                    {
                                        !load &&
                                        details?.platform === "salesforce" &&
                                        <>
                                            {fields?.order_detail?.payment_status && <div className='order-status '>{fields?.order_detail?.payment_status}</div>}
                                        </>
                                    }

                                </div>
                                <duv class="order-num order-name-main">Customer Name: <div class="order-name">{load ? "..." : details && details?.orderDetails?.customerName}</div>
                                </duv>
                            </div>
                            <span class="order-date">{load ? "..." : details && `${moment(details?.orderDetails?.orderDate).format('LLL')} from Online Store`} </span>
                        </div>
                        <div className='modal-body-style'>
                            {/* DONE */}
                            <div class="table-responsive">
                                <table class="transactions-box table table-sm">
                                    <thead class="table_heading">
                                        <tr>
                                            <th class="td-width-60">S.#</th>
                                            <th class="td-width-120">Order Date</th>
                                            <th class="td-width-100">SKU #</th>
                                            <th class="td-width-200">Description</th>
                                            <th class="td-width-80">Qty</th>
                                            <th class="td-width-100 text-right">Price</th>
                                            <th class="td-width-100 text-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            load ? "..." :
                                                details &&
                                                details?.orderDetails?.lineItems?.map((item, ind) => (
                                                    <tr>
                                                        <td>{ind + 1}</td>
                                                        <td>{moment(details?.orderDetails?.orderDate).format("MM-DD-YYYY")}</td>
                                                        <td>{item?.sku}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{item?.quantity}</td>
                                                        <td class="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                                                        <td class="text-right">{numeral(item?.price * item?.quantity).format("$0,0.0'")}</td>
                                                    </tr>
                                                ))
                                        }

                                    </tbody>
                                </table>
                            </div>

                            {/* DONE */}
                            {!load && FulfillmentTable(details)}

                            {/* DONE */}
                            <div class="order-sum mb-2">
                                <div class="paid-mid-row">
                                    <div class="left-txt multi-content">
                                        <div class="left-hd">Subtotal</div>
                                        <div class="left-info">{details?.orderDetails?.lineItems?.length}</div>
                                    </div>
                                    <div class="right-txt">{details?.orderDetails?.subtotal}</div>
                                </div>
                                <div class="paid-mid-row">
                                    <div class="left-txt multi-content">
                                        <div class="left-hd">Discount</div>
                                        <div className="left-info">
                                            {details?.orderDetails?.discountRate}
                                        </div>
                                    </div>
                                    <div className="right-txt">{details?.orderDetails?.discount}</div>
                                </div>
                                <div class="paid-mid-row">
                                    <div class="left-txt multi-content">
                                        <div class="left-hd">Shipping</div>
                                    </div>
                                    <div class="right-txt"> {details?.orderDetails?.shipping}</div>
                                </div>
                                <div class="paid-mid-row">
                                    <div class="left-txt multi-content">
                                        <div class="left-hd">Tax</div>
                                    </div>
                                    <div class="right-txt">  {details?.orderDetails?.tax}</div>
                                </div>
                                <div class="paid-mid-row">
                                    <div class="left-txt multi-content">
                                        <div class="left-hd fw-bold">Total Amount</div>
                                    </div>
                                    <div class="right-txt fw-bold"> {details?.orderDetails?.total}</div>
                                </div>
                            </div>
                            {
                                fields?.order_return_detail?.length > 0 &&
                                <>
                                    <div className='orderdetail-title'>Refund Detail</div>
                                    <div class="table-responsive">
                                        <table class="transactions-box table table-sm">
                                            <thead class="table_heading">
                                                <tr>
                                                    <th class="td-width-60">S.#</th>
                                                    <th class="td-width-120">Order Date</th>
                                                    <th class="td-width-100">SKU #</th>
                                                    <th class="td-width-200">Description</th>
                                                    <th class="td-width-80">Qty</th>
                                                    <th class="td-width-100 ">Price</th>
                                                    <th class="td-width-100 ">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    load ? "..." :
                                                        details &&
                                                            details.platform === "shopify" ?
                                                            details?.returnDetails?.map((item, ind) => {
                                                                return (
                                                                    item?.refund_line_items?.map((item2, key2) => {
                                                                        return (
                                                                            <tr key={key2}>
                                                                                <td>{key2 + 1}</td>
                                                                                <td>{moment(fields?.order_detail?.created_at).format("MM-DD-YYYY")}</td>
                                                                                <td>{item2?.line_item.sku}</td>
                                                                                <td>{item2?.line_item?.title}</td>
                                                                                <td>{item2?.quantity}</td>
                                                                                <td>{numeral(item2?.line_item?.price).format("$0,0.0'")}</td>
                                                                                <td>{numeral(item2?.subtotal).format("$0,0.0'")}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                )
                                                            }) :
                                                            details?.platform === "squarespace" ?
                                                                details?.returnDetails?.lineItems?.map((item, key) => (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td>{moment.utc(item?.createdOn).format("MM-DD-YYYY")}</td>
                                                                        <td>{item?.sku}</td>
                                                                        <td>{item?.title}</td>
                                                                        <td>{item?.quantity}</td>
                                                                        <td>{numeral(item?.price).format("$0,0.00")}</td>
                                                                        <td>{numeral(item?.subtotal).format("$0,0.00")}</td>
                                                                    </tr>
                                                                ))
                                                                : null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="order-sum">
                                        <div class="paid-mid-row">
                                            <div class="left-txt multi-content">
                                                <div class="left-hd">Subtotal </div>
                                                <div className="left-info">
                                                    {details.platform === 'shopify' ? (
                                                        <>
                                                            {details?.returnDetails?.map(orderReturn => orderReturn?.refund_line_items?.length || 0)
                                                                .reduce((acc, currentLength) => acc + currentLength, 0) || 0}
                                                            &nbsp;item
                                                        </>
                                                    ) : details.platform === 'squarespace' ? (
                                                        <>
                                                            {details?.returnDetails?.lineItems?.length} item
                                                        </>
                                                    ) : details.platform === 'salesforce' ? (
                                                        <>

                                                        </>
                                                    ) : <></>}

                                                </div>
                                            </div>
                                            <div className="right-txt">
                                                {details.platform === 'shopify' ? (
                                                    <>
                                                        {numeral(
                                                            details?.returnDetails?.map(orderReturnDetail =>
                                                                orderReturnDetail?.refund_line_items?.reduce(
                                                                    (subtotalSum, item) => subtotalSum + item.subtotal,
                                                                    0
                                                                )
                                                            )?.reduce((total, subtotal) => total + subtotal, 0)
                                                        ).format("$0,0.0'")}
                                                    </>
                                                ) : details.platform === 'squarespace' ? (
                                                    <>
                                                        {numeral(details?.returnDetails?.lineItems?.reduce((acc, item) => acc += item.subtotal, 0)).format("$0,0.0'")}
                                                    </>
                                                ) : details.platform === 'salesforce' ? (
                                                    <>

                                                    </>
                                                ) : <></>}

                                            </div>
                                        </div>

                                        {details.platform === 'shopify' ? (
                                            <>
                                                {
                                                    details?.orderDetails?.order_detail?.fulfillment_status === null &&
                                                    details?.orderDetails?.order_detail?.cancel_reason &&
                                                    details?.returnDetails.some(orderReturnDetail =>
                                                        orderReturnDetail.order_adjustments.some(item => item.kind === "shipping_refund")
                                                    ) && (
                                                        <div className="paid-mid-row">
                                                            <div className="left-txt multi-content">
                                                                <div className="left-hd">Shipping</div>
                                                            </div>
                                                            <div className="right-txt">
                                                                {numeral(
                                                                    details.returnDetails
                                                                        .map(orderReturnDetail =>
                                                                            orderReturnDetail.order_adjustments
                                                                                .filter(item => item.kind === "shipping_refund") // Ensure we only consider shipping refunds
                                                                                .reduce((totaltax, item) => totaltax + Math.abs(parseFloat(item?.amount || 0)), 0)
                                                                        )
                                                                        .reduce((total, subtotal) => total + Math.abs(subtotal), 0)
                                                                ).format("$0,0.00")}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        ) : details.platform === 'squarespace' ? (
                                            <>
                                                <div className="paid-mid-row">
                                                    <div className="left-txt multi-content">
                                                        <div className="left-hd">Shipping</div>
                                                    </div>
                                                    <div className="right-txt">
                                                        {numeral(details?.returnDetails?.shippingTotal?.value).format("$0,0.0'")}
                                                    </div>
                                                </div>
                                            </>
                                        ) : details.platform === 'salesforce' ? (
                                            <>

                                            </>
                                        ) : <></>
                                        }

                                        <div class="paid-mid-row">
                                            <div class="left-txt multi-content">
                                                <div class="left-hd">Tax</div>
                                            </div>
                                            <div className="right-txt">
                                                {details.platform === 'shopify' ? (
                                                    <>
                                                        {numeral(
                                                            details?.returnDetails?.map(orderReturnDetail =>
                                                                orderReturnDetail?.refund_line_items?.reduce((totaltax, item) => totaltax + item?.total_tax, 0)
                                                            )?.reduce((total, subtotal) => total + subtotal, 0)
                                                        ).format("$0,0.0'")}
                                                    </>
                                                ) : details.platform === 'squarespace' ? (
                                                    <>
                                                        {numeral(details?.returnDetails?.taxTotal?.value).format("$0,0.0'")}
                                                    </>
                                                ) : details.platform === 'salesforce' ? (
                                                    <>

                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div class="paid-mid-row">
                                            <div class="left-txt multi-content">
                                                <div class="left-hd fw-bold">Total Amount</div>
                                            </div>
                                            <div class="right-txt fw-bold">{numeral(computeTotalRefund()).format("$0,0.00", Math.floor)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>


                    </Col>
                </Row>
            </Box>
        </Modal >
    )
}

function mapStateToProps({ }) {
    return {}
};
export default connect(mapStateToProps, salesActions)(OrderModal)


function FulfillmentTable(data) {
    const platform = data?.platform
    if (data?.orderDetails?.fulfillments?.length > 0) {
        switch (platform) {
            case 'shopify': return (
                <>
                    <div className='orderdetail-title'>Fulfillment Status</div>
                    <div class="table-responsive">
                        <table class="transactions-box table table-sm">
                            <thead class="table_heading">
                                <tr>
                                    <th>S.#</th>
                                    <th>Tracking Company</th>
                                    <th>Tracking Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data &&
                                    data?.orderDetails?.fulfillments?.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{item?.tracking_company ? item?.tracking_company : '-'}</td>
                                                <td>{item?.tracking_number ? item?.tracking_number : '-'}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            )
            case 'squarespace': return (
                <>
                    <div className='orderdetail-title'>Fulfillment Status</div>
                    <div class="table-responsive">
                        <table class="transactions-box table table-sm">
                            <thead class="table_heading">
                                <tr>
                                    <th>S.#</th>
                                    <th>Tracking Company</th>
                                    <th>Tracking Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data &&
                                    data?.orderDetails?.fulfillments?.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{item?.carrierName ? item?.carrierName : '-'}</td>
                                                <td>{item?.trackingNumber ? item?.trackingNumber : '-'}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            )
        }
    }
}
