import {GET_ADMIN_LOGS} from "../../actions/type";

const initialState={};

export default function adminLogs(state=initialState,action){
    switch(action.type){
        case GET_ADMIN_LOGS:
            return action.payload;
        default:
            return state
    }
}