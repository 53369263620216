import React, { useEffect } from 'react'
import NoRows from "../../reUsable/NoRows"
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import numeral from 'numeral'

function DataTable({ nowGet, data }) {
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const feedCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "account_type", headerName: "Account Type", width: 150, sortable: false, renderCell: ({ value }) => value === 'brand' ? "Brand" : "User" },
        { field: "referrerPixel", headerName: "Referrer PID", width: 150, sortable: false },
        { field: "referrerName", headerName: "Referrer Name", width: 200, sortable: false },
        {
            field: "link", headerName: "Link", width: 300, sortable: false,
            renderCell: ({ value }) => {
                return (
                    <a target='_blank' href={value} >{value}</a>
                );
            },
        },
        { field: "userCount", headerName: "Landed Users", width: 200, sortable: false },
    ]
    useEffect(() => {
        setPageLoad(false)
    }, [data])
    return (
        <>
            <DataGrid
                showCellRightBorder={true}
                rowHeight={35}
                disableColumnFilter={true}
                density="compact"
                loading={pageLoad}
                components={{ NoRowsOverlay: NoRows }}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                rows={
                    data?.paginatedResults?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 20) - 19),
                        referrerPixel: item?.userDetails?.pixel_id,
                        referrerName: item?.userDetails?.name,
                        referrerEmail: item?.userDetails?.email,
                        account_type: item?.userDetails?.account_type,
                        link: process.env.REACT_APP_BASE_URL === "https://backendlive.ormelive.com/" ?
                            `https://www.ormelive.com/${item?.userDetails?.pixel_id}` :
                            `https://www.dev.ormelive.com/${item?.userDetails?.pixel_id}`
                    }))
                }
                columns={feedCol}
                rowCount={data?.total ? data?.total : 0}
                pagination
                pageSize={20}
                rowsPerPageOptions={[20]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    nowGet(newPage + 1);
                }}
            />
            <div className='sumContainer-height-235  pb-2'  >
                <div className='sumContainer' style={{ background: "#fff" }}>
                    <div className='sumTitle'>Summary</div>
                    <div className='sumBody'>
                        <div className='sumBodyRow' style={{ borderBottom: "none" }}>
                            <div className='sumBodyTitle'>Total Link in Bio Users</div>
                            <div className='sumBodyvalue'>{numeral(data?.summary?.userCount).format("0,000,000")}</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default React.memo(DataTable)