import {GET_TRANSACTION} from "../../actions/type";

const initialState=[];

export default function transactions(state=initialState,action){
    switch(action.type){
        case GET_TRANSACTION:
            return action.payload;
        default:
            return state
    }
}