import { GET_ACCOUNT_LIST } from "../../actions/type";

const initialState = {};

export default function accounts(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNT_LIST:
            return action.payload;
        default:
            return state
    }
}