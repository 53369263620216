import {ADD_MINIMIZE, MINUS_MINIZE} from "../../actions/type";

const initialState=[];

export default function minimiz(state=initialState,action){
    switch(action.type){
        case ADD_MINIMIZE:
            if(state.map(it=>it.id).indexOf(action.payload.id)===-1){
                if(state.length>=8){
                    const updatedState=[...state]
                    updatedState.splice(0,1)
                    return [...updatedState,action.payload];
                }else{
                    return [...state,action.payload]
                }
            }
            return state
        case MINUS_MINIZE:
            return [...state].filter(item=>item.id!=action.payload);
        case "clearMinimize":
            return []
        default:
            return state
    }
}