import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, FormControl, Typography, Stack, InputLabel } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as tagsAction from "../../store/actions/tags.action"
import { toast } from 'react-toastify';
import { Cancel, Tag } from "@mui/icons-material";
import { WithContext as ReactTags } from 'react-tag-input';
const KeyCodes = {
    comma: 188,
    enter: [10, 13],
}

const delimiters = [...KeyCodes.enter, KeyCodes.comma];
function Tags({ getTags, updateTags }) {
    const [tags, setTags] = useState([]);
    const [load, setLoad] = useState(true)

    useEffect(() => {
        getData()
    }, [])

    const handleSave = () => {
        setLoad(true)
        const arr = tags?.map(m => m.id)
        updateTags(arr).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
        }).finally(() => getData())
    }

    function getData() {
        getTags().then((res) => {
            if (res.success) {
                if (res?.data?.tags?.length > 0) {
                    setTags(res?.data?.tags?.map(m => ({ id: m, text: m })))
                } else {
                    setTags([])
                }
            } else {
                setTags([])
            }
        }).finally(() => setLoad(false))
    }

    function handleDelete(i) {
        const updatedTags = tags.filter((tag, index) => index !== i)
        setTags(updatedTags)
    }

    function handleAddition(tag) {
        setTags((prev) => [...prev, tag])
    }
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    console.log("tags", tags)
    return (
        <div className="container-fluid mb-3">
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Trending Search
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-between">
                <Col xl={4} lg={6} md={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Trending Keywords
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <FormControl className="field-style" size="small">
                            <ReactTags
                                tags={tags}
                                autofocus={false}
                                classNames={{
                                    tags: "tagsClass",
                                    tag: 'tagClass',
                                    remove: 'removeClass',
                                    tagInput: 'tagInputClass',
                                    tagInputField: "inputFieldClass"
                                }}
                                placeholder="Press enter or comma to add services"
                                inputFieldPosition="top"
                                handleDelete={handleDelete}
                                allowDeleteFromEmptyInput={false}
                                handleDrag={handleDrag}
                                handleAddition={handleAddition}
                                delimiters={delimiters}
                            />
                        </FormControl>
                        <Button
                            disabled={load}
                            className="btn-width btn-gen-margin ml-0 h-100"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => {
    return {};
};

export default connect(mapStateToProps, { ...tagsAction })(Tags);