import {GET_LOGS} from "../../actions/type";

const initialState={};

export default function logs(state=initialState,action){
    switch(action.type){
        case GET_LOGS:
            return action.payload;
        default:
            return state
    }
}