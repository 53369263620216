import { GET_PROMOCODES } from "store/actions/type";

const initialState={};

export default function promocodes(state=initialState,action){
    switch(action.type){
        case GET_PROMOCODES:
            return action.payload;
        default:
            return state
    }
}