import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Col, Row } from "react-bootstrap";
import { Button, Typography, Divider, MenuItem, Select, InputLabel, FormControl, TextField, Autocomplete, Box } from '@mui/material';
import Skeleton from '../../reUsable/skeleton';
import "../accountMain/components/sale.css"
import *  as feedActions from 'store/actions/feedAction'


import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback } from "react";
import SearchBrand from "reUsable/SearchBrand";
import VideoTable from "./VideoTable";
// import SearchUser from "reUsable/searchUser";
import DateBtn from "reUsable/DateBtn";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";


function FeedStats({ videoStats, getVideoFeed, getVideoFeedOld }) {
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState([
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [date, setDate] = useState(false)
    const [fields, setFields] = useState({ brand_id: '', type: "review", sort_by: 'total_views', video_id: '' })
    const [dateKey, setDateKey] = useState('today')
    const [summary, setSummary] = useState(null)

    useEffect(() => {
        // getData(fields, value)
        getDataOld({ ...fields, }, 1)
    }, [])

    function handleSearch(type) {
        if (type === "refresh") {
            setValue([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
            setFields({ brand_id: '', type: "review", sort_by: 'total_views', video_id: '' })
            // getData({ brand_id: '', type: "review", sort_by: 'total_views', video_id: '' }, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
            getDataOld({ brand_id: '', type: "review", sort_by: 'total_views', video_id: '' }, 1, 'reset')
        } else {
            getDataOld(fields, 1)
            // getData(fields, value)
        }
    }

    // const getData = (obj, dates) => {
    //     setLoading(true)
    //     getVideoFeed({ ...obj, to: dates[1], from: dates[0] }, 1).then(() => {
    //         setDate(false)
    //         setLoading(false)
    //     }).catch(err => {
    //         setLoading(false)
    //     })
    // }

    useEffect(() => {
        if (date) {
            getDataOld({ ...fields }, 1)
        }
    }, [date])

    const getDataOld = (obj, page, flag) => {
        const data = { ...obj, key: flag === 'reset' ? 'today' : dateKey }
        setLoading(true)
        getVideoFeedOld(data, page).then((res) => {
            if (res?.summary) {
                setSummary(res?.summary)
            } else {
                setSummary(null)
            }
            setDate(false)
            setLoading(false)
        })
    }

    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xl className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Video Stats
                        </Typography>

                    </Col>
                </Row>
                <DateBtn value={value} setValue={setValue} setDate={setDate} hours={true} setDateKey={setDateKey} type='userstats' />

                <Row className="account-main-row pl-15">
                    {/* <Col xl={3} lg={12} className='mb-3 pid-field-main'>
                        <TextField
                            size="small"
                            value={fields.video_id}
                            onChange={e => setFields({ ...fields, video_id: e.target.value })}
                            id="outlined-basic"
                            label="Video ID"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col> */}
                    {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col> */}
                    <Col xl={3} lg={12} className='mb-3 pid-field-main'>
                        <SearchBrand setter={(value) => setFields({ ...fields, brand_id: value })} brand={fields.brand_id} />
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Select Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.type}
                                label="Select Type"
                                onChange={(e) => {
                                    setFields({ ...fields, type: e.target.value })
                                }}
                            >
                                <MenuItem value={"show"}>Shows</MenuItem>
                                <MenuItem value={"review"}>Reviews</MenuItem>
                                <MenuItem value={"events"} disabled>Events</MenuItem>
                            </Select>
                        </FormControl>

                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Sort By</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.sort_by}
                                label="Sort By"
                                onChange={(e) => {
                                    setFields({ ...fields, sort_by: e.target.value })
                                }}
                            >
                                <MenuItem value={"total_views"}>Views</MenuItem>
                                <MenuItem value={"total_likes"}>Likes</MenuItem>
                                <MenuItem value={"total_comments"}>Comments</MenuItem>
                                <MenuItem value={"total_shares"}>Shares</MenuItem>
                                <MenuItem value={"total_sales"}>Sales</MenuItem>
                            </Select>
                        </FormControl>

                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width ml-0 laptop ml-0"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale ml-15" />
                <div style={{ height: 455 }} className="pl-3">
                    {
                        loading ? <Skeleton /> :
                            <>
                                <VideoTable
                                    nowGet={(page) => {
                                        getVideoFeedOld({ ...fields, key: dateKey }, page)
                                    }}
                                    data={videoStats}
                                    summary={summary}
                                />
                            </>
                    }
                </div>
            </div>

        </React.Fragment>
    )
}
function mapStateToProps({ videoStats, }) {
    return { videoStats, }
}
export default connect(mapStateToProps, feedActions)(FeedStats)