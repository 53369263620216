import React, { useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";

function VideoTable({ nowGet, data, summary }) {
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const feedCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "video_id", headerName: "Video ID", width: 140, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        {
            field: "title", headerName: "Video Title", width: 400, sortable: false, renderCell: ({ value }) => {
                return value ? <Tooltip title={value} placement="top" arrow><div>{value}</div></Tooltip> : <span style={{ color: "#bebebe" }}>Empty</span>
            }
        },
        { field: "brand", headerName: "Brand", width: 150, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "total_views", headerName: "Views", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_likes", headerName: "Likes", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_shares", headerName: "Shares", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "shareRation", headerName: "Share %", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_comments", headerName: "Comments", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_sales", headerName: "Sales", width: 120, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
    ]
    const [sum, setSum] = useState(false)

    const feeds = useMemo(() => {
        if (data?.success) {
            if (data?.reviewAnalytics || data?.showAnalytics) {
                setSum(false)
                setPageLoad(false)
                return data?.reviewAnalytics ? data?.reviewAnalytics?.[0] : data?.showAnalytics?.[0]
            } else {
                setSum(true)
                setPageLoad(false)
                return data
            }
        }
    }, [data])
    return (
        <>
            <DataGrid
                showCellRightBorder={true}
                rowHeight={35}
                disableColumnFilter={true}
                density="compact"
                loading={pageLoad}
                components={{ NoRowsOverlay: NoRows }}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                rows={
                    feeds?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 15) - 14),
                        brand: sum ? item?.brand_name : item?.brandData?.[0]?.name,
                        video_id: item?.id,
                        shareRation: numeral(item?.total_shares / item?.total_views).format('0.0%')
                    }))
                }
                columns={feedCol}
                rowCount={Object.keys(feeds?.data).length !== 0 ? feeds?.total : 0}
                pagination
                pageSize={15}
                rowsPerPageOptions={[15]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    nowGet(newPage + 1);
                }}
            />
            <div className='sumContainer-height-235  pb-2'  >
                <div className='sumContainer' style={{ background: "#fff" }}>
                    <div className='sumTitle'>Summary</div>
                    <div className='sumBody'>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Views</div>
                            <div className='sumBodyvalue'>{numeral(summary?.totalViews).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Shares</div>
                            <div className='sumBodyvalue'>{numeral(summary?.totalShares).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Share %</div>
                            <div className='sumBodyvalue'>{numeral(summary?.totalShares / summary?.totalViews).format("0.0%")}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Likes</div>
                            <div className='sumBodyvalue'>{numeral(summary?.totalLikes).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Comments</div>
                            <div className='sumBodyvalue'>{numeral(summary?.totalComments).format("0,000,000", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow' style={{ borderBottom: "none" }}>
                            <div className='sumBodyTitle'>Total Sales</div>
                            <div className='sumBodyvalue'>{numeral(summary?.totalPurchases).format("$0,0.00", Math.floor)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VideoTable