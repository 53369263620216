import {GET_CAMPAIGN_ONE} from "../../actions/type";

const initialState={
    data:[],
    count:0
};

export default function onCampaign(state=initialState,action){
    switch(action.type){
        case GET_CAMPAIGN_ONE:
            return {...action.payload,data:[...state.data,...action.payload.data]};;
        default:
            return state
    }
}