import { TOP_INFLUENCER } from "../../actions/type";

export default function topInfluencer(state = {}, action) {
    switch (action.type) {
        case TOP_INFLUENCER:
            return action.payload;
        default:
            return state
    }
}
