import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as accountActions from "../../../store/actions/account"
import * as feesActions from "../../../store/actions/feeSetting"
import * as feedActions from "../../../store/actions/feedAction"
import * as salesActions from "../../../store/actions/sales"

import { Row, Col, Button } from "react-bootstrap"
import SkeletonLoader from "reUsable/skeleton";
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { GoVerified } from "react-icons/go"
import { ImCross } from "react-icons/im"
import { Autocomplete, Checkbox, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment"
import Switch from '@mui/material/Switch';
import numeral from 'numeral';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteModal from "../../../reUsable/DeleteModal";
import SaveModal from "../../../reUsable/SaveModal";
import EditModal from "../../../reUsable/EditModal";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UpdateBrand from './UpdateBrand';
import EditUser from './EditUser';
import VerifiedIcon from '@mui/icons-material/Verified';
import deviceName from "reUsable/devicename"


function ViewInfo({ getEarning, earning, getFeedStats, getInfluencers, getDetails, accountDetail, currentUser, deleted, changeActive, updateBrandLogo, nowGet, userType, changeAccountType, close, fees, getFeesSetting }) {


    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [check, setCheck] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [check3, setCheck3] = useState(false);
    const [check4, setCheck4] = useState(false);
    const [influencer, setInfluencer] = useState(false)
    const [statusModal, setStatusModal] = useState(false)
    const [appModal, setAppModal] = useState(false)
    const [infModal, setInfModal] = useState(false)
    const [featuredModal, setFeaturedModal] = useState(false)
    const [tags, setTags] = useState([])
    const [records, setRecords] = useState([])
    const [house, setHouse] = useState([])
    const [isHouse, setIsHouse] = useState(false)
    const [ai, setAi] = useState(false)

    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [stats, setStats] = useState(null)
    const [fee, setFees] = useState({
        shopper_cashback: 0,
        referral_fee: 0,
        content_creator_fee: 0
    })
    const [brandModal, setBrandModal] = useState(false)

    const [brandLogo, setBrandLogo] = useState(null)

    const address = useMemo(() => {
        if (accountDetail?.userDetails?.shipping_addresses?.length === 0) {
            return null
        } else {
            return accountDetail?.userDetails?.shipping_addresses.find(f => f.is_default)

        }
    }, [accountDetail])

    useEffect(() => {
        if (process.env.REACT_APP_BASE_URL.includes("backendlive")) {
            setUrl('https://ormelive.com/')
        } else {
            setUrl('https://dev.ormelive.com/')
        }
        if (userType !== "brand") {
            getEarning(currentUser, {})
        }
        getDetails(currentUser, deleted)
            .then((res) => {
                if (userType !== "brand") {
                    getFeedStats({ pixel_id: res?.pixel_id, type: 'review' }).then((res) => {
                        if (res?.success) {
                            setStats(res?.getUserAnalyticsreviews?.[0]?.data?.[0])
                        }
                    })
                }
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (accountDetail.userDetails) {
            if (accountDetail.userDetails.became_influencer === "approved") {
                setInfluencer(true)
            } else {
                setInfluencer(false)
            }
            if (userType === "brand") {
                setFees(accountDetail?.userDetails?.fee_structure)
                if (accountDetail?.userDetails?.brandWiseInfluencer?.length > 0) {
                    setHouse(accountDetail?.userDetails?.brandWiseInfluencer?.map(item => ({
                        title: `${item?.pixel_id} - ${item?.name}`,
                        key: item?.pixel_id,
                        ...item
                    })))
                } else {
                    setHouse([])
                }
            } else {
                setFees({
                    content_creator_fee: 0,
                    referral_fee: 0,
                    shopper_cashback: 0
                })
            }
            setCheck(accountDetail.userDetails?.is_active)
            setCheck2(accountDetail.userDetails?.is_verified)
            setCheck3(accountDetail.userDetails?.is_featured)
            setCheck4(accountDetail.userDetails?.app)
            if (userType === "influencer") {
                setIsHouse(accountDetail?.userDetails?.is_house_influencer ? accountDetail?.userDetails?.is_house_influencer : false)
                setAi(accountDetail?.userDetails?.is_ai_search ? accountDetail?.userDetails?.is_ai_search : false)

            }
        }
    }, [accountDetail])

    function formatDuration(seconds) {
        if (seconds === 0) {
            return 0
        } else if (seconds <= 60) {
            return `${(seconds / 60).toFixed(1)} min`
        } else {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            if (hours) {
                return `${hours} ${hours > 1 ? 'hours' : 'hour'} ${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            } else {
                return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            }
        }

    }
    function renderData() {
        const {
            userDetails,
            packageDetails,
            followersData
        } = accountDetail

        function renderVerify(verify) {
            if (verify) {
                return <GoVerified style={{ marginLeft: 10 }} color="green" size={18} />
            } else {
                return <ImCross style={{ marginLeft: 10 }} color="red" size={14} />
            }
        }

        function renderIspaid(isPaid) {
            if (isPaid == "NONE") {
                return ""
            }
            else if (isPaid == "PAID") {
                return "green"
            }
            else if (isPaid == "UN-PAID") {
                return "red"
            }
        }
        function handleModals(type) {
            if (type === "status") {
                setCheck(false)
                setStatusModal(false)
                // changeActive({ user_id: userDetails._id, is_active: false })
                //     .then(() => {
                //         setStatusModal(false)
                //         nowGet()
                //     })
            }
            if (type === "app") {
                setCheck4(false)
                setAppModal(false)
                // changeActive({ user_id: userDetails._id, app: false })
                //     .then(() => {
                //         setAppModal(false)
                //         nowGet()
                //     })
            }
            if (type === "featured") {
                setCheck3(false)
                setFeaturedModal(false)
                // changeActive({ user_id: userDetails._id, is_featured: false })
                //     .then(() => {
                //         setFeaturedModal(false)
                //         nowGet()
                //     })
            }
            if (type === "influencer") {
                setInfluencer(false)
                setInfModal(false)
                // changeAccountType(userDetails._id)
                //     .then(() => {
                //         nowGet()
                //     })

            }
        }

        function save() {
            setModal(false)
            if (userType === "brand") {
                let influencer_ids = house?.map(i => ({ influencer_id: i?._id, influencer_pid: i?.pixel_id }))
                if (!check) {
                    setCheck4(false)
                }
                let fee_structure = { ...fee }
                if (fees?.content_creator_fee !== fee?.content_creator_fee || fees?.referral_fee !== fee?.referral_fee || fees?.shopper_cashback !== fee?.shopper_cashback) {
                    fee_structure.is_custom_fee_structure = true
                }
                if (brandLogo) {
                    updateBrandLogo(userDetails._id, brandLogo)
                }
                changeActive({ user_id: userDetails._id, is_active: check, app: check ? check4 : false, influencer_ids, fee_structure })
                    .then((res) => {
                        toast.success(res?.message, {
                            autoClose: 2000,
                            hideProgressBar: true,
                        });
                        nowGet(1)
                    })
            }
            if (userType === "customer") {
                if (!check) {
                    setCheck4(false)
                }
                changeActive({ user_id: userDetails._id, is_active: check })
                    .then((res) => {
                        toast.success(res?.message, {
                            autoClose: 2000,
                            hideProgressBar: true,
                        });
                        nowGet(1)
                    })
            }
            if (userType === "influencer") {
                if (!check) {
                    setCheck4(false)
                }
                changeActive({ user_id: userDetails._id, is_active: check, is_house_influencer: isHouse, app: check ? check4 : false, is_ai_search: ai })
                    .then((res) => {
                        toast.success(res?.message, {
                            autoClose: 2000,
                            hideProgressBar: true,
                        });
                        nowGet(1)
                    })
                // if (!influencer) {
                //     changeAccountType(userDetails._id)
                //         .then(() => {
                //             // nowGet()
                //         })
                // }
            }
        }
        function clear(body, type) {
            if (accountDetail.userDetails) {
                if (accountDetail.userDetails.became_influencer === "approved") {
                    setInfluencer(true)
                } else {
                    setInfluencer(false)
                }
                setCheck(accountDetail.userDetails?.is_active)
                setCheck2(accountDetail.userDetails?.is_verified)
                setCheck3(accountDetail.userDetails?.is_featured)
                setCheck4(accountDetail.userDetails?.app)
            }
            close()

        }

        function handleDelete(i) {
            const updatedTags = tags.filter((tag, index) => index !== i)
            setTags(updatedTags)
        }

        function handleAddition(tag) {
            setTags([...tags, tag])
        }

        function debounce(cb, delay = 500) {
            let timeout
            return (...args) => {
                clearTimeout(timeout)
                timeout = setTimeout(() => {
                    cb(...args)
                }, delay)
            }
        }
        const handleChange = (val) => {
            getInfluencers(val).then((res) => {
                if (res.success) {
                    setRecords(res?.data?.message)
                } else {
                    setRecords([])
                }
            })
        };
        const optimizedFn = debounce(handleChange)
        function renderInfo(arr) {
            const address = arr.find(f => f.is_default)
            if (address) {
                return `${address?.address1} ${address?.city}, ${address?.country}, ${address?.zip} `
            } else {
                return "N/A"
            }
        }

        return (
            <>

                <SaveModal
                    visible={modal}
                    title={`Are you sure you want to save these changes?`}
                    close={() => setModal(false)}
                    type="status"
                    call={() => save()}
                />
                <EditModal
                    visible={edit}
                    close={() => setEdit(false)}
                    value={fee}
                    setter={(n, v) => {
                        setFees({ ...fee, [n]: v })
                    }}
                    call={() => {
                        setEdit(false)
                    }}
                />
                {/* <DeleteModal
                    visible={statusModal}
                    title={`Are you sure you want to deactive the Account status?`}
                    close={() => setStatusModal(false)}
                    type="status"
                    call={() => handleModals('status')}
                />
                <DeleteModal
                    visible={appModal}
                    title={`Are you sure you want to deactive the App?`}
                    close={() => setAppModal(false)}
                    type="status"
                    call={() => handleModals('app')}
                />
                <DeleteModal
                    visible={featuredModal}
                    title={`Are you sure you want to deactive the Account featured?`}
                    close={() => setFeaturedModal(false)}
                    type="status"
                    call={() => handleModals('featured')}
                />
                <DeleteModal
                    visible={infModal}
                    title={`Are you sure you want to change the Account type to Shopper?`}
                    close={() => setInfModal(false)}
                    type="status"
                    call={() => handleModals('influencer')}
                    flag='influencer'
                /> */}

                <Row className='align-items-center'>
                    <Col sm xl={12}>
                        {

                            brandModal ?
                                <div className='modal-edit'>
                                    {userType === "brand" ?
                                        <UpdateBrand
                                            visible={brandModal}
                                            id={currentUser}
                                            close={() => setBrandModal(false)}
                                        /> :
                                        <EditUser
                                            visible={brandModal}
                                            id={currentUser}
                                            close={() => setBrandModal(false)}
                                            type={userDetails?.is_house_influencer ? "house" : "customer"}
                                        />
                                    }
                                </div>
                                :

                                <Card variant="outlined" className='users-card-main pb-0'>
                                    <div className='d-flex justify-content-between'>
                                        <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                                            User Information
                                        </Typography>
                                        <div style={{ display: "flex", gap: '5px' }}>
                                            {userDetails?.is_banned &&
                                                <div className='user-status-style' style={{
                                                    backgroundColor: 'red',
                                                }}>
                                                    Banned
                                                </div>
                                            }
                                            <div className='user-status-style' style={{
                                                backgroundColor: check ? 'green' : 'red',
                                            }}>
                                                {check ? "Active" : "Deactive"}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider className='' />

                                    <CardContent>
                                        <Row className=''>
                                            <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Basic Information</h6>
                                                {userType === "brand" ?
                                                    <div className='infoSection new mb-3' style={{ minHeight: '206px' }}>
                                                        <Row className=''>

                                                            <Col sm={11} lg={11} xl={11} className='user-info-column new mb-1'>
                                                                <span className='info-hd'> PID:</span> {userDetails.pixel_id}</Col>
                                                            <Col sm={1} lg={1} xl={1} className='user-info-column new mb-1'>
                                                                <div style={{ cursor: 'pointer' }} className='linkBrand' onClick={() => {
                                                                    setBrandModal(true)
                                                                }}>
                                                                    <EditIcon fontSize='small' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Date Created:</span> {moment(userDetails.created_at).format('MMMM Do YYYY, h:mm:ss a')}</Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Account Type:</span> {userDetails.account_type === "brand" ? "Brand" : userDetails?.account_type} </Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                                <span className='info-hd'>Package: </span>
                                                                {packageDetails ? packageDetails.package_name : <span style={userType !== "customer" ? { color: 'red' } : {}}>{userType !== "customer" ? "Not Selected" : "Shopper"}</span>} </Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Brand Name:</span> <span style={{ color: 'green' }}>{userDetails.brand_name ? userDetails.brand_name : '-'}</span> </Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Merchant Name:</span> <span style={{ color: 'green' }}>{userDetails.merchant_name ? userDetails.merchant_name : '-'}</span> </Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Contact Person:</span> <span style={{ color: 'green' }}>{userDetails.contact_name ? userDetails.contact_name : '-'}</span> </Col>

                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Email: </span>{userDetails.email ? userDetails.email : '-'} {userDetails?.email_verified && <VerifiedIcon style={{ fontSize: "1rem", color: "#3ccfff" }} />} </Col>

                                                            {/* <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Address:</span> {userDetails.address ? userDetails.address : "-"}</Col> */}

                                                        </Row>
                                                        {/* <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Address 2: </span>{userDetails.address_2 ===
                                                            "" ? "-" : userDetails.address_2}</Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>City:</span> {userDetails.city === "" ? "-" : userDetails.city}</Col>
    
                                                    </Row> */}
                                                        {/* <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>State: </span>{userDetails.state ===
                                                            "" ? "-" : userDetails.state}</Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Country: </span>{userDetails.country}</Col>
                                                    </Row> */}

                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Phone: </span>{userDetails?.brand_phone ? userDetails.brand_phone : "-"}</Col>

                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Website: </span> <a href={userDetails?.website_address.includes('http') ? userDetails?.website_address : `//${userDetails?.website_address}`} target='_blank'>{userDetails.website_address}</a> </Col>
                                                        </Row>
                                                        <Row className=''>
                                                            {/* <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Zip Code: </span>{userDetails.zip ===
                                                            "" ? "-" : userDetails.zip}</Col> */}
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Country: </span>{userDetails.country}</Col>

                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Signed Agreement: </span>{
                                                                userDetails.agreement === "accepted" ? <a href={accountDetail?.agreement?.ducument_url} target='_blank' style={{ color: "green", textDecoration: 'underline' }}>Signed</a> : <a style={{ color: "red" }} href={accountDetail?.agreement?.ducument_url} target='_blank'>Not Signed</a>
                                                            }</Col>
                                                        </Row>

                                                    </div>
                                                    :
                                                    <div className='infoSection new mb-3' style={{ minHeight: '206px' }}>
                                                        <Row className=''>
                                                            <Col sm={11} lg={11} xl={11} className='user-info-column new mb-1'>
                                                                <span className='info-hd'> PID:</span> {userDetails.pixel_id}
                                                            </Col>
                                                            <Col sm={1} lg={1} xl={1} className='user-info-column new mb-1'>
                                                                <div style={{ cursor: 'pointer' }} className='linkBrand' onClick={() => {
                                                                    setBrandModal(true)
                                                                }}>
                                                                    <EditIcon fontSize='small' />
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Date Created:</span> {moment(userDetails.created_at).format('MMMM Do YYYY, h:mm:ss a')}</Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Account Type:</span> {userDetails?.is_house_influencer ? "House" : "User"} </Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Name:</span> <span style={{ color: 'green' }}>{userDetails.name ? userDetails.name : "-"}</span> </Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Email: </span>{userDetails.email ? userDetails.email : "-"} {userDetails?.email_verified && <VerifiedIcon style={{ fontSize: "1rem", color: "#3ccfff" }} />}</Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Phone:</span> {userDetails.phone ? userDetails.phone : "-"}</Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Address:</span> {address ? address?.address1 : '-'}</Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>City:</span> {address ? address?.city : '-'}</Col>
                                                        </Row>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>State: </span>{address ? address?.province : '-'}</Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Country: </span>{address ? address?.country : "-"}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Zip Code: </span>{address ? address?.zip : '-'}</Col>
                                                        </Row>
                                                    </div>
                                                }

                                            </Col>

                                            {userType === "brand" &&
                                                <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                    <h6 className='titleVie'>Social Information</h6>
                                                    <div className='infoSection new mb-3' style={{ minHeight: '206px' }}>
                                                        <Row className=''>
                                                            <Col sm={12} className='user-info-column new mb-1'><span className='info-hd'>Package: </span>{packageDetails ? packageDetails.package_name : <span style={{ color: 'red' }}>Not Selected</span>}</Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Instagram: </span>{userDetails?.fb_token === "" ? <span style={{ color: 'red' }}>Not Connected</span> : userDetails.instagram_username === "" ? <span style={{ color: 'red' }}>Not Connected</span> : <a target="_blank" href={`https://www.instagram.com/${userDetails.instagram_username}`}>{userDetails.instagram_username}</a>}</Col>
                                                            {userType === "brand" &&
                                                                <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Facebook:</span> {userDetails.fb_token === "" ? <span style={{ color: 'red' }}>Not Connected</span> : <span style={{ color: 'green' }}>Connected</span>}</Col>
                                                            }

                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>ORME Profile: </span>{<a target="_blank" href={process.env.REACT_APP_BASE_URL.includes('backendlive') ? `https://ormelive.com/${userDetails.pixel_id}` : `https://dev.ormelive.com/${userDetails.pixel_id}`}>{userDetails.name}</a>}</Col>
                                                            <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'>
                                                                <div style={{ display: 'flex', alignItems: "center", gap: 10 }}>
                                                                    <div style={{ width: "100px", height: "100px", border: '1px solid #e7e7e7', display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                                                        {userDetails?.brand_logo || brandLogo ?
                                                                            <img style={{ width: "98px", height: "98px", objectFit: "contain" }} src={
                                                                                brandLogo ? URL.createObjectURL(brandLogo) : userDetails?.brand_logo ? userDetails?.brand_logo : ''
                                                                            } /> : ""
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type='file'
                                                                            onChange={(e) => {
                                                                                if (e.target.files[0]) {
                                                                                    console.log(e.target.files)
                                                                                    setBrandLogo(e.target.files[0])
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            }
                                            {userType !== "brand" && <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Connection Setup</h6>
                                                <div className='infoSection new mb-3' style={{ minHeight: '206px' }}>
                                                    <Row className=''>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Shipping: {renderInfo(userDetails?.shipping_addresses)}</span></Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'> Payment Method: {accountDetail?.paymentMethods ? <span className='fw-bold' style={{ textTransform: "uppercase" }}>{accountDetail?.paymentMethods?.data?.[0]?.card?.brand} <span>**** **** **** {accountDetail?.paymentMethods?.data?.[0]?.card?.last4}</span></span> : <span style={{ color: "red" }}>Not Connected</span>} </span></Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'> Paypal Status: {userDetails?.paypal_email?.length > 1 ? <span className='info-hd' style={{ color: 'green' }}>Connected</span> : <span className='info-hd' style={{ color: 'red' }}>Not Connected</span>} </span></Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Paypal Email: {userDetails?.paypal_email ? userDetails?.paypal_email : 'N/A'}</span></Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Device:</span> {userDetails.device ? userDetails.device : "Web"}</Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Device Name:</span>
                                                            {userDetails.device_brand ? <span style={
                                                                { textTransform: "capitalize" }
                                                            }>{`${userDetails?.device_brand} ${userDetails?.device === "ios" ? deviceName(userDetails?.device_name) : userDetails?.device_name}`}</span> : "-"}</Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>OS Version:</span> {userDetails.device_version ?
                                                            <span >{`${userDetails?.device_version}`}</span> : "-"}</Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Version:</span> {userDetails.version ? userDetails.version : "-"}</Col>
                                                    </Row>
                                                </div>
                                            </Col>}
                                            {userType !== "brand" && <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Stats</h6>
                                                <div className='infoSection new mb-3' style={{ minHeight: '155px' }}>
                                                    <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Watch Time: {stats ? formatDuration(stats?.total_duration) : 0}</span></Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Views: {stats ? stats?.total_views : 0}  </span></Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Shares: {stats ? stats?.total_shares : 0}</span> </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Likes: {stats ? stats?.total_likes : 0} </span></Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Saves: {stats ? stats?.total_saves : 0} </span></Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>ORME Profile: </span>{<a target="_blank" href={process.env.REACT_APP_BASE_URL.includes('backendlive') ? `https://ormelive.com/${userDetails.pixel_id}` : `https://dev.ormelive.com/${userDetails.pixel_id}`}>{userDetails.name}</a>}</Col>

                                                        {/* <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                            <span className='info-hd'>ORME Profile:</span>
                                                            {userDetails?.form_name !== "" ? <a target='_blank' href={`${url}${userDetails?.pixel_id}`}>{userDetails?.pixel_id}</a> : "N/A"}
                                                        </Col> */}
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'>
                                                            <span className='info-hd'>ORME Followers:</span> {userDetails?.orme_follower ? numeral(userDetails?.orme_follower).format('0,000,000,000') : "0"}
                                                        </Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'>
                                                            <span className='info-hd'>ORME Following:</span>{userDetails?.orme_following ? numeral(userDetails?.orme_following).format('0,000,000,000') : "0"}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>}
                                            {userType !== "brand" && <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Performance Monitoring</h6>
                                                <div className='infoSection new mb-3' style={{ minHeight: '155px' }}>
                                                    <Row className=''>
                                                        {/* numeral(accountDetail?.withdraw).format("$0,0.00", Math.floor) */}
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Purchases: {numeral(accountDetail?.purchaseSummary?.[0]?.gross_amount).format("$0,0.00", Math.floor)} </span></Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Referral Sales: {numeral(earning?.message?.lifetime_earning?.[0]?.customer_commission).format("$0,0.00", Math.floor)}  </span></Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Content Sales: {numeral(earning?.message?.lifetime_earning?.[0]?.creator_commission).format("$0,0.00", Math.floor)}</span> </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Withdrawals: {numeral(accountDetail?.withdraw).format("$0,0.00", Math.floor)} </span></Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Returns: {numeral(accountDetail?.purchaseSummary?.[0]?.returned_amount).format("$0,0.00", Math.floor)} </span></Col>
                                                    </Row>
                                                </div>
                                            </Col>}

                                            <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Operations</h6>
                                                <div className='infoSection new mb-3' style={userType === "brand" ? { minHeight: '83px' } : { minHeight: '190px' }}>
                                                    <Row className=''>

                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-2'>
                                                            <FormControl className='field-style pid-fields' size="small">
                                                                <span className='info-hd mb-1'>Account Status:</span>
                                                                <Select
                                                                    disabled={userDetails?.deleted}
                                                                    sx={{ color: "black" }}
                                                                    value={check}
                                                                    // label="Account Status"
                                                                    onChange={(e) => setCheck(e.target.value)}
                                                                >
                                                                    <MenuItem value={true}>Active</MenuItem>
                                                                    <MenuItem value={false}>Deactive</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                        </Col>
                                                        {
                                                            userType === "influencer" &&
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-2'>
                                                                <FormControl className='field-style pid-fields' size="small">
                                                                    <span className='info-hd mb-1'>House Influencer:</span>
                                                                    <Select
                                                                        disabled={userDetails?.deleted}
                                                                        sx={{ color: "black" }}
                                                                        value={isHouse}
                                                                        // label="Account Status"
                                                                        onChange={(e) => setIsHouse(e.target.value)}
                                                                    >
                                                                        <MenuItem value={true}>Yes</MenuItem>
                                                                        <MenuItem value={false}>No</MenuItem>
                                                                    </Select>
                                                                </FormControl>

                                                            </Col>


                                                        }
                                                        <>
                                                            {
                                                                <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                                    <FormControl className='field-style pid-fields' size="small">
                                                                        <span className='info-hd mb-1'>Public:</span>
                                                                        <Select
                                                                            disabled={userDetails?.deleted || !check}
                                                                            sx={{ color: "black" }}
                                                                            value={check4}
                                                                            onChange={(e) => setCheck4(e.target.value)}
                                                                        >
                                                                            <MenuItem value={true}>Active</MenuItem>
                                                                            <MenuItem value={false}>Deactive</MenuItem>
                                                                        </Select>
                                                                    </FormControl>

                                                                </Col>
                                                            }


                                                        </>

                                                        {
                                                            userType === "influencer" &&
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-2'>
                                                                <FormControl className='field-style pid-fields' size="small">
                                                                    <span className='info-hd mb-1'>AI Search:</span>
                                                                    <Select
                                                                        disabled={userDetails?.deleted}
                                                                        sx={{ color: "black" }}
                                                                        value={ai}
                                                                        // label="Account Status"
                                                                        onChange={(e) => setAi(e.target.value)}
                                                                    >
                                                                        <MenuItem value={true}>On</MenuItem>
                                                                        <MenuItem value={false}>Off</MenuItem>
                                                                    </Select>
                                                                </FormControl>

                                                            </Col>
                                                        }


                                                    </Row>
                                                </div>

                                            </Col>
                                            {userType === "brand" &&
                                                <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                    <h6 className='titleVie'>House Influencers</h6>
                                                    <div className='infoSection new mb-3' style={{ minHeight: "100px" }}>
                                                        <Row className=''>
                                                            <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'>

                                                                <FormControl className='field-style pid-fields' size="small">
                                                                    <span className='info-hd mb-1'>Influencers:</span>
                                                                    <Autocomplete
                                                                        size="small"
                                                                        className="field-style"
                                                                        limitTags={2}
                                                                        multiple
                                                                        clearIcon={false}
                                                                        disableCloseOnSelect
                                                                        id="tags-outlined"
                                                                        onChange={(e, newValue) => {
                                                                            setHouse(newValue)
                                                                            getInfluencers().then((res) => {
                                                                                if (res.success) {
                                                                                    setRecords(res?.data?.message)
                                                                                } else {
                                                                                    setRecords([])
                                                                                }
                                                                            })
                                                                        }}
                                                                        value={house}
                                                                        getOptionLabel={(option) => option.title}
                                                                        options={records?.map(item => ({
                                                                            title: `${item?.pixel_id} - ${item?.name}`,
                                                                            key: item?.pixel_id,
                                                                            ...item
                                                                        }))}
                                                                        filterSelectedOptions
                                                                        renderOption={(props, option) => {
                                                                            let flag = house.find(f => f.pixel_id === option.pixel_id) ? true : false
                                                                            return (
                                                                                <li {...props} style={flag ? { pointerEvents: "none", opacity: "0.7" } : {}}>
                                                                                    {option.title}
                                                                                </li>
                                                                            )
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                onChange={(e) => {
                                                                                    optimizedFn(e.target.value)
                                                                                }}
                                                                                placeholder='Search Influencers.'
                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            }

                                            {/* {
                                                userDetails?.account_type === "influencer" &&
                                                <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                    {
                                                        // userType !== "brand" &&
                                                        <>
                                                            <h6 className='titleVie'>Social</h6>
                                                            <div className='infoSection new mb-3' style={{ minHeight: "80px" }}>
                                                                <Row className=''>
                                                                    <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                                        <span className='info-hd'>Instagram:</span>
                                                                        {
                                                                            userDetails?.form_name !== "" ?
                                                                                <a target='_blank' href={`https://www.instagram.com/${userDetails?.form_name}/`}>{userDetails?.form_name}</a> : "-"
                                                                        }



                                                                    </Col>
                                                                    <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                                        <span className='info-hd'>Instagram Followers:</span>
                                                                        {userDetails?.no_of_followers ? numeral(userDetails?.no_of_followers).format('0,000,000,000') : "-"}
                                                                    </Col>
                                                                </Row>
                                                                <Row className=''>
                                                                    <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                                        <span className='info-hd'>ORME:</span>
                                                                        {
                                                                            userDetails?.form_name !== "" ?
                                                                                <a target='_blank' href={`${url}${userDetails?.pixel_id}`}>{userDetails?.pixel_id}</a> : "-"
                                                                        }
                                                                    </Col>
                                                                    <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                                        <span className='info-hd'>ORME Followers:</span>
                                                                        {userDetails?.orme_follower ? numeral(userDetails?.orme_follower).format('0,000,000,000') : "0"}
                                                                    </Col>
                                                                    <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                                        <span className='info-hd'>ORME Following:</span>
                                                                        {userDetails?.orme_following ? numeral(userDetails?.orme_following).format('0,000,000,000') : "0"}
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        </>
                                                    }
                                                </Col>
                                            } */}
                                            {userType === "brand" &&
                                                <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                    <h6 className='titleVie'>Settings</h6>
                                                    <div className='infoSection new mb-3' style={{ minHeight: '140px' }}>
                                                        <Row className=''>
                                                            <Col sm={11} lg={11} xl={11} className='user-info-column new mb-1'>
                                                                <span className='info-hd'>E-Commerce Platform :</span>
                                                                {
                                                                    userDetails.shopify ?
                                                                        <span style={{ fontWeight: "600" }}>Shopify <span style={{ color: 'green' }}>Connected</span></span> :
                                                                        userDetails?.salesforce ?
                                                                            <span style={{ fontWeight: "600" }}>Salesforce <span style={{ color: 'green' }}>Connected</span></span> :
                                                                            userDetails?.magento ? <span style={{ fontWeight: "600" }}>Magento <span style={{ color: 'green' }}>Connected</span></span> :
                                                                                <span style={{ color: 'red' }}>Not Connected</span>
                                                                }
                                                            </Col>
                                                            <Col sm={1} lg={1} xl={1} className='user-info-column new mb-1'>
                                                                <EditIcon sx={{ cursor: "pointer", color: "#052977" }} fontSize='small' onClick={() => { setEdit(true) }} />
                                                            </Col>
                                                            {/* <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Shipping Method: </span><span style={{ fontWeight: '600' }}>{userDetails?.shipping_methods?.find(f => f.is_default)?.name}</span> </Col> */}
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>ORME Fee:</span> {userDetails?.contract?.fee}% </Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Shopper Cashback:</span> {fee?.shopper_cashback}% </Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Referral Fee: </span>{fee?.referral_fee}% </Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Creator Fee: </span>{fee?.content_creator_fee}% </Col>
                                                            {/* <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Shopper Cashback / Referral Fee:</span> {fees?.shopper_cashback}% {userDetails?.fee_structure?.shopper_cashback ? `${userDetails?.fee_structure?.shopper_cashback}%` : "-"}</Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Influencer Fee: </span>{fees?.influencer_fee}% {userDetails?.fee_structure?.influencer_fee ? `${userDetails?.fee_structure?.influencer_fee}%` : "-"}</Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Content Creator Fee: </span>{fees?.content_creator_fee}% {userDetails?.fee_structure?.content_creator_fee ? `${userDetails?.fee_structure?.content_creator_fee}%` : "-"}</Col> */}

                                                        </Row>
                                                    </div>
                                                </Col>
                                            }
                                            {userType === "brand" &&
                                                <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                    <h6 className='titleVie'>Payout Information</h6>
                                                    <div className='infoSection new mb-3' style={{ minHeight: "140px" }}>
                                                        <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Stripe Account: </span>{userDetails.is_stripe_account_connected ? <span style={{ color: 'green' }}>Connected</span> : <span style={{ color: 'red' }}>Not Connected</span>}</Col>
                                                            {/* <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Payment Status:</span>
                                                                {
                                                                    userDetails?.isPaid ?
                                                                        <span style={{ color: renderIspaid(userDetails.isPaid) }}>{userDetails.isPaid?.toLowerCase()}</span> :
                                                                        <span >None</span>

                                                                }
                                                            </Col> */}
                                                        </Row>
                                                        {/* <Row className=''>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Total Addon Payment:</span> {userDetails.totalAddonsAmount ? numeral(userDetails.totalAddonsAmount).format("$0,0.00", Math.floor) : "$0.00"} </Col>
                                                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Total Subscription Payment:</span> {userDetails.totalSubscriptionAmount ? numeral(userDetails.totalSubscriptionAmount).format("$0,0.00", Math.floor) : "$0.00"}</Col>
                                                        </Row> */}
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                        <Button
                                            className=" btn-gen-margin ml-0  btn-width btn-gen-margin grey-btn-outline h-100 resetBTN"
                                            size="medium"
                                            variant="outlined"
                                            // style={{ marginTop: "10px !important" }}
                                            sx={{ color: 'gray', }}

                                            onClick={clear}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                                            size="medium"
                                            variant="contained"
                                            onClick={() => {
                                                setModal(true)
                                            }}
                                        // disabled={url === ""}
                                        >
                                            Save
                                        </Button>
                                    </CardContent>
                                </Card>


                        }

                    </Col>

                </Row >


            </>
        )
    }

    return (
        <React.Fragment>
            {loading ? (
                <SkeletonLoader />
            ) : renderData()}
        </React.Fragment>
    );
}

function mapStateToProps({ accountDetail, fees, earning }) {
    return { accountDetail, fees, earning }
}

export default connect(mapStateToProps, { ...accountActions, ...feesActions, ...feedActions, ...salesActions })(ViewInfo)