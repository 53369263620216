import { GET_CAMPAIGN_TRANSACTION } from "../../actions/type";

const initialState={
    data:[],
    count:0
};

export default function campaignTransaction(state=initialState,action){
    switch(action.type){
        case GET_CAMPAIGN_TRANSACTION:
            return action.payload
        default:
            return state
    }
}