import {GET_INFLUECNERs} from "../../actions/type";

const initialState=[]

export default function influencers(state=initialState,action){
    switch(action.type){
        case GET_INFLUECNERs:
            return action.payload;;
        default:
            return state
    }
}