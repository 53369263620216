import {GET_APPROVED_SUBS } from "../../actions/type";

const initialState=[];

export default function approveSubs(state=initialState,action){
    switch(action.type){
        case GET_APPROVED_SUBS:
            return action.payload;
        default:
            return state
    }
}