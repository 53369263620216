import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import * as accountActions from "../../store/actions/account";
import { Button, Paper, Chip } from "@mui/material";
import "../table.css";
import Switch from "@mui/material/Switch";
import * as perActions from ".../../store/actions/authPermissions";
import { connect } from "react-redux";
import UserModal from "../../reUsable/UserModal";
import * as minimizActions from "../../store/actions/minimiz";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteModal from "reUsable/DeleteModal";
import Tooltip from "@mui/material/Tooltip";
import NoRows from "../../reUsable/NoRows";
import moment from "moment";
import { useEffect } from "react";
import SkeletonLoader from "reUsable/skeleton";
import devicename from "reUsable/devicename"

function SwitchBtn({ value, deleteMasterUser, makeMasterUser, reloadData }) {
  const permission = value?.permission[0]?.is_login_permission ? true : false;

  const [check, setCheck] = React.useState(
    value?.permission[0]?.is_login_permission
  );

  React.useEffect(() => {
    setCheck(permission);
  }, [value]);
  return (
    <Switch
      color="success"
      checked={check}
      onChange={() => {
        setCheck(!check);
        if (check) {
          deleteMasterUser(value.permission[0]?._id).then(() => {
            reloadData();
          });
        } else {
          makeMasterUser(value._id).then(() => {
            reloadData();
          });
        }
      }}
    />
  );
}

function CustomizeSwitchBtn({ value, _onChange }) {
  const [check, setCheck] = React.useState(false);

  React.useEffect(() => {
    setCheck(value);
  }, [value]);
  return (
    <Switch
      color="success"
      size="small"
      checked={check}
      onChange={(e) => {
        setCheck(e.target.checked);
        _onChange(e.target.checked);
        // changeActive({ user_id: data._id, is_active: e.target.checked }).then(
        //   () => {
        //     reloadData();
        //   }
        // );
      }}
    // onChange={() => {
    //   setCheck(!check);
    //   _onChange();
    //   if (check) {
    //     console.log("IIIIIFFFFFFF", value);
    //   } else {
    //     console.log("EELLLSSEEEEF", value);
    //   }
    // }}
    />
  );
}

function SwitchBtn2({ data, reloadData, changeActive }) {
  const [check, setCheck] = React.useState(false);

  React.useEffect(() => {
    setCheck(data.is_active);
  }, [data]);
  return (
    <Switch
      color="success"
      size="small"
      checked={check}
      onChange={(e) => {
        setCheck(e.target.checked);
        changeActive({ user_id: data._id, is_active: e.target.checked }).then(
          () => {
            reloadData();
          }
        );
      }}
    />
  );
}
function DataTable({
  user,
  deleteUser,
  accounts,
  nowGet,
  tableLoading,
  changeActive,
  makeMasterUser,
  deleteMasterUser,
  minimize,
  minimizActions,
  deleteMinimize,
  noRows,
  id,
  type,
  editUser,
  search,
  loadTable,
  userTypes,
  timezone,
  date,
  fields
}) {
  const columns = [
    {
      field: "sNo",
      headerClassName: "super-app-theme--header",
      headerName: "S.no",
      minWidth: 50,
      maxWidth: 50,
      flex: 0.5,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value}</span>
        );
      },
      filterable: false, sortable: false
    },
    {
      field: "pixel_id",
      headerClassName: "super-app-theme--header",
      headerName: "PID",
      minWidth: 100,
      maxWidth: 100,
      flex: 0.5,
      filterable: false, sortable: false,
      renderCell: (params) => {
        if (process.env.REACT_APP_BASE_URL === "https://backendlive.ormelive.com/") {
          return <a target="_blank" href={`https://www.ormelive.com/${params?.row?.pixel_id}`}>{params?.row?.pixel_id}</a>
        } else {
          return <a target="_blank" href={`https://dev.ormelive.com/${params?.row?.pixel_id}`}>{params?.row?.pixel_id}</a>
        }
      }
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Created On",
      minWidth: 100,
      maxWidth: 100,
      flex: 0.5,
      filterable: false, sortable: false,
      renderCell: ({ value }) => {
        return timezone ? moment.utc(value).format('MM-DD-YYYY') : moment(value).format('MM-DD-YYYY')
      }
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      renderCell: (params) => {
        return (
          // <Link to={`/accountMain/detail/${params.value.id}`}>
          <span
            className="account-name-hover-style"
            onClick={() => {
              setImage(params?.row?.profile_image_url)
              setUserType(params?.row?.account_type)
              setCurrentUser(params.row._id);
              setUserName(params.row.name);
              if (params.row.deleted) {
                setCurrentDeleted(params.row.deleted);
              }
              setCurrentCustomerId(params.row.customer_id);
              setUserModal(true);
            }}
          >
            {params.row.name}
          </span>
          // </Link>
        );
      },
      minWidth: 220,
      maxWidth: 220,
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "status",
      minWidth: 100,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      renderCell: (params) => {
        let val = params?.value
        // if (val.agreement === "new") {
        //   return <p className="mb-0">New</p>
        // } else if (!val?.is_active) {
        //   return <p className='mb-0' style={{ color: "red" }}> Deactive</p>
        // } else if (!(val.agreement === "accepted") &&
        //   (val.hasOwnProperty('shopify') || val.hasOwnProperty('salesforce') || val.hasOwnProperty('magento')) &&
        //   ((!val.is_stripe_account_connected && val.contract.is_stripe_connect_user === false) || (val.is_stripe_account_connected && val.contract.is_stripe_connect_user))
        // ) {
        //   return "Incomplete"
        // } else {
        //   return <p className='mb-0' style={{ color: "green" }}>Active</p>
        // }
        if (val.is_deleted) {
          return <span style={{ color: "red" }}>Deleted</span>
        }
        if (val.agreement === "new") {
          return <p className="mb-0">New</p>
        } else if (!val?.is_active) {
          return (<p className='mb-0' style={{ color: val.deleted ? "red" : val?.is_active ? "green" : "red" }}>
            {val.deleted ? "Deleted" : val?.is_active ? "Active" : "Deactive"}
          </p>)
        } else {
          if (!(val.agreement === "accepted" &&
            (val.hasOwnProperty('shopify') || val.hasOwnProperty('salesforce') || val.hasOwnProperty('magento') || val.hasOwnProperty('squarespace'))
            &&
            ((!val.is_stripe_account_connected && val.contract.is_stripe_connect_user === false) ||
              (val.is_stripe_account_connected && val.contract.is_stripe_connect_user)
            ))) {
            return "Incomplete"
          } else {
            return (<p className='mb-0' style={{ color: val.deleted ? "red" : val?.is_active ? "green" : "red" }}>
              {val.deleted ? "Deleted" : val?.is_active ? "Active" : "Deactive"}
            </p>)
          }
        }
        // return (
        //   <SwitchBtn2
        //     data={params.value}
        //     changeActive={changeActive}
        //     reloadData={() => nowGet(page)}
        //   />
        // );
      },
      flex: 1,
      filterable: false, sortable: false
    },
    // {
    //   field: "device",
    //   minWidth: 70,
    //   maxWidth: 70,
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Device",
    //   renderCell: (params) => {
    //     return (<span >{params?.row?.device ? params?.row?.device.charAt(0).toUpperCase() + params?.row?.device.slice(1) : "Web"}</span>)
    //   },
    //   flex: 1,
    //   filterable: false, sortable: false
    // },
    {
      field: "app",
      minWidth: 100,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      headerName: "Public",
      renderCell: (params) => {
        return (<p className='mb-0' style={{ color: params?.row?.app ? "green" : "red" }}>{params?.row?.app ? "On" : "Off"}</p>)
        // return (
        //   params?.row?.account_type === "follower" ? <span>-</span> : <CustomizeSwitchBtn
        //     value={params?.row?.app}
        //     _onChange={(e) => {
        //       changeActive({
        //         user_id: params?.row._id,
        //         app: e,
        //       }).then(() => {
        //         nowGet(page);
        //       });
        //     }}
        //   />
        // );
      },
      flex: 1,
      filterable: false, sortable: false
    },
    // {
    //   field: "packageName",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Package",
    //   minWidth: 90,
    //   maxWidth: 90,
    //   renderCell: (params) => {
    //     // setUserType(params.value.account_type)
    //     if (params.value.account_type !== "customer") {
    //       return params?.value?.packageName ? params?.value?.packageName : "-";
    //     } else {
    //       return <span>Customer</span>;
    //     }
    //   },
    //   flex: 1,
    //   filterable: false, sortable: false
    // },
    // {
    //   field: "email",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Email",
    //   flex: 1.5,
    //   minWidth: 230,
    //   maxWidth: 230,
    //   filterable: false, sortable: false
    // },
    {
      field: "agreement",
      headerClassName: "super-app-theme--header",
      headerName: "Agreement",
      minWidth: 120,
      maxWidth: 120,
      renderCell: ({ value }) => {
        if (value === "rejected") {
          return <p className="not-connected">Rejected</p>
        } else if (value === "accepted") {
          return <p className="not-connected" style={{ color: 'green' }}>Accepted</p>
        } else if (value === "new") {
          return <span style={{ color: "#bebebe" }}>Empty</span>
        } else {
          return value
        }
      },
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "stripe_type",
      headerClassName: "super-app-theme--header",
      headerName: "Stripe Type",
      flex: 1.5,
      minWidth: 140,
      maxWidth: 140,
      filterable: false, sortable: false,
      renderCell: (params) => {
        return params?.row?.contract?.is_stripe_connect_user ? "Stripe Connect" : "Stripe"
      }
    },
    {
      field: "stripe_status",
      headerClassName: "super-app-theme--header",
      headerName: "Stripe Status",
      flex: 1.5,
      minWidth: 140,
      maxWidth: 140,
      filterable: false, sortable: false,
      renderCell: (params) => {
        // console.log("1", params?.row?.contract?.is_stripe_connect_user == undefined)
        if (!params?.row?.is_stripe_account_connected && params?.row?.contract?.is_stripe_connect_user === false) {
          //normalStripe
          return <span style={{ color: 'green' }}>Connected</span>
        } else if (!params?.row?.is_stripe_account_connected && params?.row?.contract?.is_stripe_connect_user == undefined) {
          return <span style={{ color: 'red' }}>Not Connected</span>
        } else if (params?.row?.is_stripe_account_connected && params?.row?.contract?.is_stripe_connect_user) {
          //stipe Connect
          return <span style={{ color: 'green' }}>Connected</span>
        } else {
          return <span style={{ color: 'red' }}>Not Connected</span>
        }
      }
    },
    {
      field: "ecom",
      headerClassName: "super-app-theme--header",
      headerName: "ECOM Type",
      flex: 1.5,
      minWidth: 140,

      maxWidth: 140,
      filterable: false, sortable: false,
      renderCell: (params) => {
        if (params?.row?.shopify) {
          return <span>Shopify</span>
        } else if (params?.row?.salesforce) {
          return <span>Salesforce</span>
        } else if (params?.row?.magento) {
          return <span>Magento</span>
        } else if (params?.row?.squarespace) {
          return <span>Squarespace</span>
        } else {
          return <span style={{ color: "red" }}>Not Connected</span>
        }
      }
    },
    {
      field: "ecom_status",
      headerClassName: "super-app-theme--header",
      headerName: "ECOM Status",
      flex: 1.5,
      minWidth: 140,
      maxWidth: 140,
      filterable: false, sortable: false,
      renderCell: (params) => {
        if (params?.row?.shopify || params?.row?.salesforce || params?.row?.magento || params?.row?.squarespace) {
          return <span style={{ color: 'green' }}>Connected</span>
        } else {
          return <span style={{ color: 'red' }}>Not Connected</span>
        }
      }
    },
    {
      field: "instagram_username",
      headerClassName: "super-app-theme--header",
      headerName: "Instagram",
      minWidth: 140,
      maxWidth: 140,
      renderCell: (params) => {
        if (params?.row?.fb_token) {
          if (params.value !== "") {
            return (
              <a
                target="_blank"
                href={`https://www.instagram.com/${params.value}`}
              >
                {params.value}
              </a>
            )
          } else {
            return <p className="not-connected">Not Connected</p>;
          }
        } else {
          return <p className="not-connected">Not Connected</p>;
        }
      },
      flex: 1,
      filterable: false, sortable: false
    },

    // {
    //   field: "bioshop",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "KBShop",
    //   minWidth: 120,
    //   maxWidth: 120,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return (
    //         <a target="_blank" href={`https://konnect.bio/${params.value}`}>
    //           {params.value}
    //         </a>
    //       );
    //     } else {
    //       return <p className="not-connected">Not Connected</p>;
    //     }
    //   },
    //   flex: 1,
    //   filterable: false, sortable: false
    // },
    // {
    //   field: "account_type",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Account Type",
    //   minWidth: 130,
    //   flex: 1,
    //   filterable: false,
    // },



    // {
    //   field: "featured",
    //   minWidth: 90,
    //   maxWidth: 90,
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Featured",
    //   renderCell: (params) => {
    //     return (<p className='mb-0' style={{ color: params?.row?.is_featured ? "green" : "red" }}>{params?.row?.is_featured ? "Yes" : "No"}</p>)
    //     // return (
    //     //   params?.row?.account_type === "follower" ? <span>-</span> : <CustomizeSwitchBtn
    //     //     value={params?.row?.is_featured}
    //     //     _onChange={(e) => {
    //     //       changeActive({
    //     //         user_id: params?.row._id,
    //     //         is_featured: e,
    //     //       }).then(() => {
    //     //         nowGet(page);
    //     //       });
    //     //     }}
    //     //   />
    //     // );
    //   },
    //   flex: 1,
    //   filterable: false, sortable: false
    // },

    // {
    //   field: 'payment',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Payment",
    //   minWidth: 150,
    //   renderCell: ({value}) => {
    //     if(!(value.package_id) || value.package_id=="61c02d43f40bec74fac2c9a0"){
    //       return "-"
    //     }
    //     else if (value.used_promoCode?.code || value.is_trial_user) {
    //       return <p className='not-connected'>Unpaid</p>
    //     }
    //     else {
    //       return <p className="not-connected text-success">Paid</p>
    //     }
    //   },
    //   flex: 1,
    //   filterable: false
    // },
    // {
    //     field:'country',
    //     headerClassName: 'super-app-theme--header',
    //     headerName:"Country",
    //     flex:1,
    //     minWidth:120,
    //     filterable:false
    // },
    //   {
    //     field:'state',
    //     headerClassName: 'super-app-theme--header',
    //     headerName:"State",
    //     flex:1,
    //     minWidth:120,
    //     filterable:false
    // },
    //     {
    //       field:'city',
    //       headerClassName: 'super-app-theme--header',
    //       headerName:"City",
    //       flex:1,
    //       minWidth:120,
    //       filterable:false
    //   },
    //   {
    //     field:'is_trial_user',
    //     minWidth:100,
    //     headerClassName: 'super-app-theme--header',
    //     headerName:"Trial",
    //     renderCell:(params)=>{
    //       return <p className='mb-0'>{params.value?"Yes":"No"}</p>
    //     },
    //     flex:1,
    //     filterable:false
    // },
    //   {
    //     field:'used_promoCode',
    //     minWidth:140,
    //     headerClassName: 'super-app-theme--header',
    //     headerName:"Promocode",
    //     renderCell:(params)=>{
    //       return <p className='mb-0'>{params.value?params.value.code:"-"}</p>
    //     },
    //     flex:1,
    //     filterable:false
    // },

    // {
    //     field:'amount',
    //     headerClassName: 'super-app-theme--header',
    //     headerName:"Amount",
    //     minWidth:100,
    //     flex:1,
    //     filterable:false
    // },
    // {
    //   field:'created_at',
    //   headerClassName: 'super-app-theme--header',
    //   headerName:"Created Date",
    //   minWidth:200,
    //   flex:1,
    //   filterable:false
    // },
    // {
    //   field:'permission',
    //   headerClassName: 'super-app-theme--header',
    //   headerName:"Master User",
    //   minWidth:130,
    //   renderCell:({value})=>{
    //     return(
    //       <div>
    //         <SwitchBtn
    //         value={value}
    //         reloadData={()=>nowGet(page)}
    //         deleteMasterUser={deleteMasterUser}
    //         makeMasterUser={makeMasterUser}
    //         />
    //     </div>
    //     )
    //   },
    //   flex:1,
    //   filterable:false
    // },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Actions",
      flex: 1.5,
      filterable: false, sortable: false,
      minWidth: 150,
      // maxWidth: 150,
      textAlign: "center",
      renderCell: (params) => {
        return (
          <div className="action-buttons-div">
            {params.value.deleted ? null : (
              <>
                <Tooltip title="Update Details" placement="left" arrow>
                  <Button
                    className="btn-gen-margin"
                    style={{ color: "primary", borderColor: "primary" }}
                    variant="contained"
                    onClick={() => {
                      setImage(params?.row?.profile_image_url)
                      setUserType(params?.row?.account_type)
                      setCurrentUser(params.value._id);
                      setUserName(params.value.name);
                      setUserModal(true);
                    }}
                    disableElevation
                  >
                    <AiOutlineEdit color={"#ffffff"} size={15} />
                  </Button>
                </Tooltip>
                <Button
                  className='btn-gen-margin'
                  variant="contained"
                  color='error'
                  disableElevation
                  onClick={() => {
                    setCurrentUser(params.value._id)
                    setModal(true)
                  }}
                >
                  <RiDeleteBinLine
                    color={'white'}
                    size={15}
                  />
                </Button>
              </>
            )}
            {/* <Tooltip title="View KBShop" placement="right" arrow>
              <Button
                className="btn-gen-margin"
                disabled={params.value.instagram_username ? false : true}
                style={params.value.instagram_username !== "" ? { color: "#010B40", borderColor: "#010B40", padding: 0 } : { color: "grey", borderColor: "grey", padding: 0 }}
                variant="outlined"
              >
                <a
                  target="_blank"
                  style={{ textDecoration: "inherit" }}
                  href={`https://konnect.bio/${params.value.instagram_username}`}
                >
                  <AiOutlineEye color={params.value.instagram_username !== "" ? "#010B40" : "gray"} size={15} />
                </a>
              </Button>
            </Tooltip> */}
          </div >
        );
      },
    },
  ];
  const columnsCustomer = [
    {
      field: "sNo",
      headerClassName: "super-app-theme--header",
      headerName: "S.no",
      minWidth: 50,
      maxWidth: 50,
      flex: 0.5,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value}</span>
        );
      },
      filterable: false,
      sortable: false
    },
    {
      field: "pixel_id",
      headerClassName: "super-app-theme--header",
      headerName: "PID",
      minWidth: 100,
      maxWidth: 100,
      flex: 0.5,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.account_type === "influencer") {
          if (process.env.REACT_APP_BASE_URL === "https://backendlive.ormelive.com/") {
            return <a target="_blank" href={`https://ormelive.com/${params?.row?.pixel_id}`}>{params?.row?.pixel_id}</a>
          } else {
            return <a target="_blank" href={`https://dev.ormelive.com/${params?.row?.pixel_id}`}>{params?.row?.pixel_id}</a>
          }
        } else {
          return params?.row?.pixel_id
        }
      }

    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Created On",
      minWidth: 110,
      maxWidth: 110,
      flex: 0.5,
      filterable: false, sortable: false,
      renderCell: ({ value }) => {
        // return moment(value).format('MM-DD-YYYY')
        return timezone ? moment.utc(value).format('MM-DD-YYYY') : moment(value).format('MM-DD-YYYY')
      }
    },
    {
      field: "is_ai_search",
      headerClassName: "super-app-theme--header",
      headerName: "AI Search",
      minWidth: 110,
      maxWidth: 110,
      flex: 0.5,
      filterable: false, sortable: false,
      renderCell: ({ value }) => {
        // return moment(value).format('MM-DD-YYYY')
        return (<p className='mb-0' style={{ color: value ? "green" : "red" }}>{value ? "On" : "Off"}</p>)
      }
    },
    // {
    //   field: "account_type",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Account Type",
    //   minWidth: 120,
    //   maxWidth: 120,
    //   flex: 0.5,
    //   filterable: false, sortable: false,
    //   renderCell: (params) => {
    //     if (params?.row?.account_type === "influencer" && params?.row?.is_house_influencer) {
    //       return "House"
    //     } else {
    //       return "User"
    //     }
    //   }
    // },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      renderCell: (params) => {
        return (
          // <Link to={`/accountMain/detail/${params.value.id}`}>
          <span
            className="account-name-hover-style"
            onClick={() => {
              setImage(params?.row?.profile_image_url)
              setUserType(params?.row?.account_type)
              setCurrentUser(params.row._id);
              setUserName(params.row.name);
              if (params.row.deleted) {
                setCurrentDeleted(params.row.deleted);
              }
              setCurrentCustomerId(params.row.customer_id);
              setUserModal(true);
            }}
          >
            {params.row.name ? params.row.name : <span style={{ color: "#bebebe" }}>Empty</span>}
          </span>
          // </Link>
        );
      },
      minWidth: 180,
      maxWidth: 180,
      flex: 1,
      filterable: true,
      sortable: false


    },
    {
      field: "status",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      renderCell: (params) => {
        if (params?.row?.is_deleted) {
          return <span style={{ color: "red" }}>Deleted</span>
        } else {
          return (<p className='mb-0' style={{ color: params?.row?.deleted ? "red" : params?.value?.is_active ? "green" : "red" }}>
            {params?.row?.deleted ? "Deleted" : params?.value?.is_active ? "Active" : "Deactive"}
          </p>)
        }
        // return (
        //   <SwitchBtn2
        //     data={params.value}
        //     changeActive={changeActive}
        //     reloadData={() => nowGet(page)}
        //   />
        // );
      },
      flex: 1,
      filterable: false,
      sortable: false

    },
    {
      field: "device",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: "super-app-theme--header",
      headerName: "Device",
      renderCell: (params) => {
        return (<span >{params?.row?.device ? params?.row?.device.charAt(0).toUpperCase() + params?.row?.device.slice(1) : "Web"}</span>)
      },
      flex: 1,
      filterable: false,
      sortable: false
    },

    {
      field: "device_detail",
      minWidth: 180,
      maxWidth: 180,
      headerClassName: "super-app-theme--header",
      headerName: "Device Name",
      renderCell: (params) => {
        if (params?.row?.device_brand) {
          if (params?.row?.device === "ios") {
            return <span style={{ textTransform: "capitalize" }}>{`${params?.row?.device_brand} ${devicename(params?.row?.device_name)}`}</span>
          } else {
            return <span style={{ textTransform: "capitalize" }}>{`${params?.row?.device_brand} ${params?.row?.device_name}`}</span>
          }
        } else {
          return <span style={{ color: "#bebebe" }}>Empty</span>
        }
      },
      flex: 1,
      filterable: false,
      sortable: false
    },
    {
      field: "device_version",
      minWidth: 120,
      maxWidth: 120,
      headerClassName: "super-app-theme--header",
      headerName: "OS Version",
      renderCell: (params) => {
        if (params?.row?.device_version) {
          return <span style={{ textTransform: "capitalize" }}>{`${params?.row?.device_version}`}</span>
        } else {
          return <span style={{ color: "#bebebe" }}>Empty</span>
        }
      },
      flex: 1,
      filterable: false,
      sortable: false
    },
    {
      field: "app",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: "super-app-theme--header",
      headerName: "Public",
      renderCell: (params) => {
        return (<p className='mb-0' style={{ color: params?.row?.app ? "green" : "red" }}>{params?.row?.app ? "On" : "Off"}</p>)
        // return (
        //   params?.row?.account_type === "follower" ? <span>-</span> : <CustomizeSwitchBtn
        //     value={params?.row?.app}
        //     _onChange={(e) => {
        //       changeActive({
        //         user_id: params?.row._id,
        //         app: e,
        //       }).then(() => {
        //         nowGet(page);
        //       });
        //     }}
        //   />
        // );
      },
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "version",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: "super-app-theme--header",
      headerName: "Version",
      renderCell: (params) => {
        if (params?.row?.version) {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>{params?.row?.version}</span>
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
      flex: 1,
      filterable: false,
      sortable: false
    },
    // {
    //   field: "packageName",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Package",
    //   minWidth: 130,
    //   maxWidth: 130,
    //   renderCell: (params) => {
    //     setUserType(params.value.account_type)
    //     if (params.value.account_type !== "customer") {
    //       return params?.value?.packageName ? params?.value?.packageName : "-";
    //     } else {
    //       return <span>Shopper</span>;
    //     }
    //   },
    //   flex: 1,
    //   filterable: false,
    //   sortable: false,
    // },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      flex: 1.5,
      minWidth: 250,
      maxWidth: 250,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span>{params.row.email ? params.row.email : <span style={{ color: "#bebebe" }}>Empty</span>}</span>
      }
    },
    {
      field: "phone",
      headerClassName: "super-app-theme--header",
      headerName: "Phone No.",
      flex: 1.5,
      minWidth: 150,
      maxWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <span>{params.row.phone ? params.row.phone : "-"}</span>
      }
    },
    {
      field: "otp_verified",
      headerClassName: "super-app-theme--header",
      headerName: "OTP",
      flex: 1.5,
      minWidth: 120,
      maxWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => value ? <span style={{ color: 'green' }}>Verified</span> : <span style={{ color: 'red' }}>Not Verified</span>
    },
    {
      field: "email_verified",
      headerClassName: "super-app-theme--header",
      headerName: "Email Verified",
      flex: 1.5,
      minWidth: 120,
      maxWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return <span>{value ? <span style={{ color: "green" }}>Verified</span> : <span style={{ color: "red" }}> Not Verified</span>}</span>
      }
    },
    {
      field: "paypal_email",
      headerClassName: "super-app-theme--header",
      headerName: "Paypal",
      flex: 1.5,
      minWidth: 120,
      maxWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return <span>{value ? value !== "" ? <span style={{ color: "green" }}>Connected</span> : <span style={{ color: "red" }}> Not Connected</span> : <span style={{ color: "red" }}> Not Connected</span>}</span>
      }
    },
    {
      field: "shipping_addresses",
      headerClassName: "super-app-theme--header",
      headerName: "Shipping",
      flex: 1.5,
      minWidth: 120,
      maxWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return <span>{value ? value?.length > 0 ? <span style={{ color: "green" }}>Connected</span> : <span style={{ color: "red" }}> Not Connected</span> : <span style={{ color: "red" }}> Not Connected</span>}</span>
      }
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Actions",
      flex: 1.5,
      filterable: false,
      minWidth: 150,
      textAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="action-buttons-div">
            {params.value.deleted ? null : (
              <>
                <Tooltip title="Update Details" placement="left" arrow>
                  <Button
                    className="btn-gen-margin"
                    style={{ color: "primary", borderColor: "primary" }}
                    variant="contained"
                    onClick={() => {
                      setImage(params?.row?.profile_image_url)
                      setUserType(params?.row?.account_type)
                      setCurrentUser(params.value._id);
                      setUserName(params.value.name);
                      setUserModal(true);
                    }}
                    disableElevation
                  >
                    <AiOutlineEdit color={"#ffffff"} size={15} />
                  </Button>
                </Tooltip>
                <Button
                  className='btn-gen-margin'
                  variant="contained"
                  color='error'
                  disableElevation
                  onClick={() => {
                    setCurrentUser(params.value._id)
                    setModal(true)
                  }}
                >
                  <RiDeleteBinLine
                    color={'white'}
                    size={15}
                  />
                </Button>
              </>
            )}
            {/* <Tooltip title="View Bioshop" placement="right" arrow>
              <Button
                className="btn-gen-margin"
                disabled={params.value.instagram_username ? false : true}
                style={{ color: "grey", borderColor: "grey", padding: 0 }}
                variant="outlined"
              >
                <a
                  target="_blank"
                  style={{ textDecoration: "inherit" }}
                  href={`https://konnect.bio/${params.value.instagram_username}`}
                >
                  <AiOutlineEye color={"grey"} size={15} />
                </a>
              </Button>
            </Tooltip> */}
          </div>
        );
      },
    },
  ];

  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [userModal, setUserModal] = React.useState(editUser ? true : false);
  const [currentUser, setCurrentUser] = React.useState("");
  const [currentDeleted, setCurrentDeleted] = React.useState(false);
  const [currentCustomerId, setCurrentCustomerId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [userType, setUserType] = React.useState(null)
  const [image, setImage] = React.useState('')
  useEffect(() => {
    setPage(1)
  }, [date])
  useEffect(() => {
    if (!search) {
      setPage(1)
    }
  }, [search])
  useEffect(() => {
    if (editUser) {
      setImage(editUser?.profile_image_url)
      setUserType(editUser?.account_type)
      setCurrentUser(editUser?._id);
      setUserName(editUser?.name);
      setCurrentCustomerId(editUser?.customer_id);
      setUserModal(true);
    }
    // if (id) {
    //   console.log("uid", id)
    //   setUserType(type)
    //   setCurrentUser(id);
    //   setUserModal(true);
    // }
  }, [editUser])
  function onDelete() {
    deleteUser(currentUser).then(() => {
      nowGet(page);
    });
    setModal(false);
  }
  function renderCol() {
    if (user === "customer") {
      if (userTypes === undefined) {
        columnsCustomer.splice(9, 1, {
          field: "agency",
          minWidth: 100,
          maxWidth: 100,
          headerClassName: "super-app-theme--header",
          headerName: "Source By",
          renderCell: (params) => {
            if (params?.row?.agency) {
              return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>{params?.row?.agencyData?.company_name ? params?.row?.agencyData?.company_name : <span style={{ color: 'gray' }}>Empty</span>}</span>
            } else {
              return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>ORME</span>
            }
          },
          flex: 1,
          filterable: false,
          sortable: false
        })
        return columnsCustomer
      } else {
        columnsCustomer.splice(11, 3)
        return columnsCustomer
      }
    } else {
      if (userType === "influencer") {
        columns.splice(9, 1, {
          field: "version",
          minWidth: 100,
          maxWidth: 100,
          headerClassName: "super-app-theme--header",
          headerName: "Version",
          renderCell: (params) => {
            if (params?.row?.version) {
              return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>{params?.row?.version}</span>
            } else {
              return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
            }
          },
          flex: 1,
          filterable: false,
          sortable: false
        })
      }
      return columns
    }
  }

  return (
    <div style={{ height: 470 }}>
      <DeleteModal
        visible={modal}
        title="Are you sure you want to delete this user?"
        close={() => setModal(false)}
        call={onDelete}
      />
      <UserModal
        visible={userModal}
        close={() => {
          setUserModal(false);
          // deleteMinimize(currentUser)
        }}
        userImage={image}
        userName={userName}
        deleted={currentDeleted}
        currentCustomerId={currentCustomerId}
        currentUser={currentUser}
        nowGet={() => nowGet(page)}
        userType={userType}
      />
      {loadTable ? <div className="pl-15" style={{ marginTop: 16 }}><SkeletonLoader /></div>
        : (
          search ?
            <DataGrid
              density="compact"
              disableColumnFilter={true}
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              sx={{ marginTop: 2 }}
              className="bg-white ml-15"
              components={{ NoRowsOverlay: NoRows, }}
              showCellRightBorder
              rows={
                accounts?.message?.length > 0 ?
                  accounts?.message?.map((item, i) => {
                    return {
                      ...item,
                      plan: "basic",
                      permission: { permission: item.permission, _id: item._id },
                      packageName: { packageName: item.packageName, account_type: item.account_type },
                      amount: item.amount ? "$" + item.amount : 0,
                      id: item._id,
                      payment: item,
                      actions: item,
                      bioshop: item.instagram_username,
                      status: item,
                      sNo: i + (page == 1 ? 1 : page * 10 - 9),
                    };
                  }) : []}
              columns={renderCol()}
              pagination
              pageSize={10}
              rowsPerPageOptions={[10]}
              rowCount={accounts?.count ? accounts?.count : 0}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPage(newPage + 1);
                setLoading(true);
                nowGet(newPage + 1)
              }}
              loading={tableLoading}
            /> : null
        )}
      <div className="modal-listing-min-window campaign-bottom-bar">
        <Paper variant="outline" className="d-flex min-window-sub-box">
          {minimize.map((item) => {
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  deleteMinimize(item.id);
                  setCurrentUser(item.id);
                  setUserName(item.userName);
                  setUserModal(true);
                }}
                className="d-flex min-chip"
              >
                <p className="mb-0 chip-title">{item.userName}</p>
                <div
                  onClick={() => {
                    deleteMinimize(item.id);
                    setCurrentUser(item.id);
                    setUserName(item.userName);
                    setUserModal(true);
                  }}
                  className="chip-icon-window"
                >
                  <OpenInNewIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteMinimize(item.id);
                  }}
                  className="chip-icon-cancel"
                >
                  <ClearIcon />
                </div>
              </div>
            );
          })}
        </Paper>
      </div>
    </div>
  );
}

function mapStateToProps({ minimize, editUser }) {
  return { minimize, editUser };
}
export default connect(mapStateToProps, {
  ...perActions,
  ...minimizActions,
  ...accountActions,
})(DataTable);
