import {GET_SUBSCRIBTION } from "../../actions/type";

const initialState=[];

export default function cancelSubs(state=initialState,action){
    switch(action.type){
        case GET_SUBSCRIBTION:
            return action.payload;
        default:
            return state
    }
}