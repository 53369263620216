import { GET_ACCESS } from "../../actions/type";

const initialState=[];

export default function access(state=initialState,action){
    switch(action.type){
        case GET_ACCESS:
            return action.payload;
        default:
            return state
    }
}