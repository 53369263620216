import {PACKAGE_DETAIL } from "../../actions/type";

const initialState={};

export default function packageDetail(state=initialState,action){
    switch(action.type){
        case PACKAGE_DETAIL:
            return action.payload;
        default:
            return state
    }
}